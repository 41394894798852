import styled from "styled-components";

export const TableButton = styled.button`
  width: 38px;
  height: 38px;
  border: none;
  background-color: #EFE9E9;
  border-radius: 2px; 
   cursor: pointer;
    color: #9F2539;
  margin:0px 5px;
  font-size: 26px;
  &:hover,
  &:focus {
    color: #E29685;
  }     
`;
export const ActionLinkButton = styled.button`

  border: none;
  background-color: transparent;
color: #6d5454;
  border-radius: 2px; 
   cursor: pointer;
  margin:0px 5px;
  font-size: 14px;
  &:hover {
    color: #9f2539;
  } 
  [data-topbar='dark']{
   color: white;
  }    
`;
export const TableIcon = styled.i`
  font-size: 16px;
  padding-top: 2px;
  color: #9F2539;
    cursor: pointer;
`;
export const VerticalLine = styled.span`
     border-left: 1px solid #9F2539;
            height: 100%;
            margin:0px 5px;     
`