import React, { useState } from "react";
import { Button, Label } from "reactstrap";

function NotificationBulk({ SaveButton }) {
  const [checkedStatus, setCheckedStatus] = useState("");

  const handleSave = () => {
    SaveButton({ is_active: checkedStatus });
  };

  const handleRadioButtons = (e) => {
    setCheckedStatus(e.target.value);
  };
  return (
    <div>
      <div className="mb-3">
        <div className="form-check mb-3">
          <h6>
            Status<span style={{ color: "#dc3545;" }}>*</span>
          </h6>
          {/* <div className="d-flex">
            <input
              type="radio"
              id="age1"
              style={{ marginBottom: "0.4rem" }}
              defaultChecked
              name="is_active"
              onChange={(e) => handleRadioButtons(e)}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Default</label>
          </div> */}

          <div className="d-flex">
            <input
              type="radio"
              id="age1"
              name="is_active"
              style={{ marginBottom: "0.4rem" }}
              onChange={(e) => handleRadioButtons(e)}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Active</label>
          </div>

          <div className="d-flex">
            <input
              type="radio"
              id="age2"
              name="is_active"
              style={{ marginBottom: "0.4rem" }}
              value="INACTIVE"
              onChange={(e) => handleRadioButtons(e)}
              className="me-1"
            />
            <label htmlFor="age2">InActive</label>
          </div>
        </div>
      </div>
      <Button
        onClick={() => handleSave()}
        className="btn btn-primary save-user"
        style={{ float: "right", marginTop: "1rem", width: "95px" }}
      >
        {" "}
        Save
      </Button>
    </div>
  );
}

export default NotificationBulk;
