import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import FeatherIcon from "feather-icons-react";
function Step1({ setSelectedModule, selectedModule }) {
  const HandleSelectedValueofSelected = (value) => {
    setSelectedModule(value);
  };
  return (
    <Row className="p-3 g-2 StepperModuleStep">
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() =>
            HandleSelectedValueofSelected("/market_intelligence/brand")
          }
          className={
            selectedModule === "/market_intelligence/brand"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-watermark"></i>
          <h4 className="font-size-16 mt-1 ms-2 ">Brand</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("area/city")}
          className={
            selectedModule === "area/city"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-chart-areaspline-variant"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">City</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("area/country")}
          className={
            selectedModule === "area/country"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-chart-areaspline-variant"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Country</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("department")}
          className={
            selectedModule === "department"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-cog"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Department</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("designation")}
          className={
            selectedModule === "designation"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-cog"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Designation</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("employee")}
          className={
            selectedModule === "employee"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-account-group"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Employee</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("notifications/email")}
          className={
            selectedModule === "notifications/email"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-bell-badge-outline"></i>

          <h4 className="font-size-16 mt-1 ms-2">Notifications</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("channel/outlet?api_type=list")}
          className={
            selectedModule === "channel/outlet?api_type=list"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-store"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Outlet</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("/inventory/plant")}
          className={
            selectedModule === "/inventory/plant"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-factory"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Plant</h4>
        </li>
      </Col>

      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("/inventory/product")}
          className={
            selectedModule === "/inventory/product"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-package-variant-closed"></i>

          <h4 className="font-size-16 mt-1 ms-2">Product</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("area/region")}
          className={
            selectedModule === "area/region"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-chart-areaspline-variant"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Region</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("area/sub_zone")}
          className={
            selectedModule === "area/sub_zone"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-chart-areaspline-variant"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">SubZone</h4>
        </li>
      </Col>
      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("user")}
          className={
            selectedModule === "user"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-account-group"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Users</h4>
        </li>
      </Col>

      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("/inventory/warehouse")}
          className={
            selectedModule === "/inventory/warehouse"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-warehouse"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Warehouse</h4>
        </li>
      </Col>

      <Col sm="4" lg="3" className="bulkupdate1ststep1strow">
        <li
          onClick={() => HandleSelectedValueofSelected("area/zone")}
          className={
            selectedModule === "area/zone"
              ? "d-flex flex-inline m-3 active bulkupdateSelectedShadow"
              : "d-flex flex-inline m-3 "
          }
        >
          <i className="mt-1 mdi mdi-chart-areaspline-variant"></i>

          <h4 className="font-size-16 mt-1 ms-2 ">Zone</h4>
        </li>
      </Col>
    </Row>
  );
}

export default Step1;
