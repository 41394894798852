import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Form,
  Row,
  Col,
  Toast,
  Label,
  Button,
  Table,
  Collapse,
} from "reactstrap";
import DetailModal from "../../../Common/DetailModal";
import FilterModal from "./FilterModal";
import TableActionApproved from "./TableActionApproved.js";
import TableActions from "../../../Common/TableActions";
import TableActionHeader from "../../../Common/TableActionsHeader";
import Breadcrumbs from "../../../Common/Breadcrumb";
import TableContainer from "../../../Common/TableContainer";
import {
  AsyncSelectCommon,
  InputCommon,
  SelectCommon,
} from "../../../Common/FormsCommon";
import * as Yup from "yup";
import { loadOneTimeOptions } from "../../../Common/optionRequest";

import { ToastContainer, toast } from "react-toastify";
import { clearFormFields } from "../../../Common/ClearDependencyArray";
import { handleValidtionSubmit } from "../../../Common/handleValidationError";
import { ApiCall } from "../../../Common/CommonCallApi";

import Swal, {
  SweetAlert,
  swal,
} from "../../../../node_modules/sweetalert2/dist/sweetalert2.all.min.js";
import { onHandleCustomPageCommon } from "../../../Common/onHandleCustomPageCommon";
import { onHandleBackCustomPageCommon } from "../../../Common/onHandleBackCustomPageCommon";
import { handleSearchCommon } from "../../../Common/handleSearchCommon";
import { onClickDeleteCommon } from "../../../Common/onClickDeleteCommon";
import { onChangeLimitCommon } from "../../../Common/onChangeLimitCommon";
import { handleCheckedCommon } from "../../../Common/handleChecked";
import { handleSelectAllCommon } from "../../../Common/handleSelectAll";
import { handleSortByCommon } from "../../../Common/handleSortByCommon";
import { handleUpdateCommon } from "../../../Common/handleUpdate";
import Loader from "../../../Common/Loader";
import downloadExcel from "../../../Common/downloadExcel";
import { handleInputChangeCommon } from "../../../Common/handleInputChangeCommon";
import TableLoader from "../../../Common/TableLoader";

function GlobalApprovals() {
  const ComponentAPI = "workflows/inbox?api_type=list";
  document.title = "Global Approvals | Lucky Cement";

  const initialValue = {
    comment: "",
  };
  const [isFilter, setIsFilter] = useState(false);

  const [form, setForm] = useState(initialValue);
  const [loading, setloading] = useState(true);
  const [activeTab, setactiveTab] = useState("");
  const [modules, setmodules] = useState([]);
  const [subZones, setsubZone] = useState([]);
  const [modal, setModal] = useState(false);
  const [ActionModal, setActionModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isEditColumn, setIsEditColumn] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [count, setCount] = useState(0);
  const [sortBy, setSortBy] = useState();
  const [brandType, setbrandType] = useState([]);
  const [filterValues, setFilterValues] = useState();
  const [searchKeyWord, setSearchKeyword] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [ValueOfAction, setValueOfAction] = useState(false);
  const [ActionDetailsAPi, setActionDetailsAPi] = useState({});
  const [ActionDetailsAPiCount, setActionDetailsAPiCount] = useState({});
  const [previousFilterValues, setPreviousFilterValue] = useState();
  const [countries, setCountries] = useState([]);

  const [ActionDetails, setActionDetails] = useState({});

  const handleSortBy = (sort_by) => {
    handleSortByCommon(
      ComponentAPI,
      sort_by,
      offset,
      limit,
      setsubZone,
      setloading,
      setSortBy,
      setOffset,
      setCount
    );
  };
  const handleSelectAll = () => {
    handleSelectAllCommon(
      subZones,
      checkedList,
      isSelectAll,
      setCheckedList,
      setIsSelectAll
    );
  };

  useEffect(() => {
    ApiCall(
      "get",
      ComponentAPI,
      { offset, limit },
      setsubZone,
      setloading,
      setCount
    );
    ApiCall(
      "get",
      "workflows/doc_feature",
      { offset, limit },
      setmodules
      // setloading
      // setCount
    );
  }, []);

  const handleChecked = (item) => {
    handleCheckedCommon(item, checkedList, setIsSelectAll, setCheckedList);
  };

  const handletextChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const toggle = () => {
    setModal(!modal);

    if (brandType.length == 0) {
      ApiCall(
        "get",
        "/market_intelligence/brand_type",
        { is_active: "ACTIVE" },
        setbrandType
      );
    }
    setForm(initialValue);
  };

  const scrollContainer = document.querySelector(".scrollable-container");

  const onHandleCustomPage = (off, cou) => {
    onHandleCustomPageCommon(
      ComponentAPI,
      off,
      cou,
      limit,
      sortBy,
      searchKeyWord,
      setsubZone,
      setloading,
      setCount,
      setOffset,
      setCheckedList,
      setIsSelectAll,
      filterValues
    );
  };

  function transformData(originalData) {
    let b = originalData.map((item) => {
      return {
        name: item.model_name,
        id: item?.id,
      };
    });

    return [{ name: "All", id: "" }, ...b];
  }

  const tabObj = transformData(modules);
  const onHandleBackCustomPage = (off, cou) => {
    onHandleBackCustomPageCommon(
      ComponentAPI,
      off,
      cou,
      limit,
      sortBy,
      searchKeyWord,
      setsubZone,
      setloading,
      setCount,
      setOffset,
      setCheckedList,
      setIsSelectAll,
      filterValues
    );
  };

  const handleSearch = (searchValue) => {
    handleSearchCommon(
      ComponentAPI,
      limit,
      setsubZone,
      setloading,
      setCount,
      setOffset,
      searchValue,
      setSearchKeyword
    );
  };

  const onChangeLimit = (e) => {
    onChangeLimitCommon(
      ComponentAPI,
      e,
      setLimit,
      setOffset,
      sortBy,
      filterValues,
      searchKeyWord,
      setsubZone,
      setloading,
      setCount
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: () => {
          return (
            <input
              readOnly
              checked={
                loading === false &&
                subZones.length === checkedList.length &&
                checkedList.length > 0
              }
              onClick={handleSelectAll}
              type="checkbox"
              className="form-check-input"
            />
          );
        },
        accessor: "checkbox",
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              readOnly
              checked={
                checkedList.filter(
                  (emp) => emp?.id === cellProps.row.original.id
                ).length > 0
              }
              onClick={(event) => {
                event.stopPropagation();
                handleChecked(cellProps.row.original);
              }}
              className="form-check-input"
            />
          );
        },
      },
      {
        Header: "Approval ID",
        sort_text: "approval_id",

        accessor: "approval_id",
        Cell: (cellProps) => <>{cellProps?.row?.original?.approval_id}</>,
      },

      {
        Header: "Created at",
        sort_text: "",

        accessor: "",
        Cell: (cellProps) => <>{cellProps?.row?.original?.created_at}</>,
      },
      {
        Header: "Initiated by",
        sort_text: "",

        accessor: "",
        Cell: (cellProps) => <>{cellProps?.row?.original?.initiated_by}</>,
      },
      {
        Header: "Feature Name",
        sort_text: "feature",

        accessor: "feature",
        Cell: (cellProps) => <>{cellProps?.row?.original?.feature}</>,
      },
      {
        Header: "Outlet Name",
        sort_text: "outlet",

        Cell: (cellProps) => <>{cellProps?.row?.original?.outlet}</>,
      },
      {
        Header: "Action",
        sort_text: "action",

        accessor: "action",
        Cell: (cellProps) => <>{cellProps?.row?.original?.action}</>,
      },

      {
        Header: "Status",
        accessor: "status",
        sort: false,
        Cell: (cellProps) => (
          <>
            {cellProps?.row?.original?.status == "Pending" ? (
              <Badge color="warning" style={{ width: "60px" }}>
                Pending
              </Badge>
            ) : cellProps.row.original.status == "Rejected" ? (
              <Badge color="danger" style={{ width: "60px" }}>
                Rejected
              </Badge>
            ) : (
              <Badge color="success" style={{ width: "60px" }}>
                Approved
              </Badge>
            )}
          </>
        ),
      },
      {
        Header: "date",
        sort_text: "date",

        accessor: "date",
        Cell: (cellProps) => <>{cellProps?.row?.original?.date}</>,
      },
      // {
      //   Header: "Initiated By",
      //   sort_text: "initiated_by",

      //   accessor: "initiated_by",
      //   Cell: (cellProps) => <>{cellProps?.row?.original?.initiated_by}</>,
      // },
      {
        Header: "Action",
        // accessor: "status",
        sort: false,
        Cell: (cellProps) => (
          <>
            <span
              onClick={() => handleDetails(cellProps?.row?.original)}
              style={{ fontSize: "18px" }}
              className="mdi mdi-timer-stop-outline text-primary me-2 hoverIcon  cursor-pointer"
            ></span>
            <span
              onClick={() => handleAccept(cellProps?.row?.original)}
              style={{ fontSize: "18px" }}
              className="mdi mdi-check-circle text-primary  me-2  hoverIcon  cursor-pointer"
            ></span>
            <span
              onClick={() => handleReject(cellProps?.row?.original)}
              style={{ fontSize: "18px" }}
              className="mdi mdi-close-circle text-primary  me-2  hoverIcon  cursor-pointer"
            ></span>
          </>
        ),
      },
    ],
    [checkedList.length, loading, offset]
  );
  const SubmitAction = (val, modal) => {
    if (val.ValueOfAction === "Accept") {
      if (form.comment == "") {
        return toast.error("Please add comment");
      }
      ApiCall("update", ComponentAPI, {
        id: selectedRowData.id,
        status: "Approved",
        comment: form.comment,
      }).then((res) => {
        if (res != undefined) {
          ApiCall(
            "get",
            ComponentAPI,
            { offset, limit },
            setsubZone,
            setloading,
            setCount
          );

          if (modal == "modal") {
            toggle();
            // toggleActionModal()
          } else {
            toggleActionModal();
          }
        }
      });
    } else {
      if (form.comment == "") {
        return toast.error("Please add comment");
      }
      ApiCall("update", ComponentAPI, {
        id: selectedRowData.id,
        status: "Rejected",
        comment: form.comment,
      }).then((res) => {
        if (res != undefined) {
          ApiCall(
            "get",
            ComponentAPI,
            { offset, limit },
            setsubZone,
            setloading,
            setCount
          );

          if (modal == "modal") {
            toggle();
            // toggleActionModal()
          } else {
            toggleActionModal();
          }
        }
      });
    }
  };

  const handleAccept = (val) => {
    setValueOfAction("Accept");
    setModal(!modal);
  };
  const handleReject = (val) => {
    setModal(!modal);
    setValueOfAction("Rejected");
  };

  const toggleActionModal = () => {
    setForm(initialValue);

    setActionModal(!ActionModal);
  };

  const [down, setDown] = useState([]);

  const renderJsonData = (data) => {
    return Object.keys(data)
      .filter((key) => !/Id/i.test(key)) // Filter out any key that includes "id" (case-insensitive)
      .map((key) => {
        if (Array.isArray(data[key])) {
          return (
            <div className="accordion" id="accordionExample" key={key}>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  {/* <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {key}
                  </button> */}
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data[key].map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{renderJsonData(item)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (typeof data[key] === "object" && data[key] !== null) {
          return (
            <div key={key}>
              <p>{key}:</p>
              {renderJsonData(data[key])}
            </div>
          );
        } else {
          return (
            <div key={key} className="d-flex justify-content-between border text-dark mb-2 mt-1 p-2">
              <p className="fw-bolder m-0">{key}:</p>
              <p className="m-0">{data[key]}</p>
            </div>
          );
        }
      });
  };
  
  const handleClearFilter = () => {
    setPreviousFilterValue();
    ApiCall(
      "get",
      ComponentAPI,
      { offset: 0, limit, ...sortBy },
      setsubZone,
      setloading,
      setCount
    );
    // dispatch(onGetSubZones({ offset: 0, limit, ...sortBy }));
    setFilterValues();
  };
  const onShowFilter = () => {
    ApiCall("get", "workflows/get_initiators", { params: "all" }, setCountries);

    setIsFilter(true);
  };
  const handleActionTabClicl = (index) => {
    setactiveTab(index.name);
    ApiCall(
      "get",
      `${ComponentAPI}&module=${index.id}`,
      { offset, limit },
      setsubZone,
      setloading,
      setCount
    );
  };
  let customData = {
    id: 255,
    order_details: [
      {
        unit: "Bags",
        quantity: 256,
        price: 255,
      },
      {
        unit: "Bags",
        quantity: 369,
        name: "LUCKY STAR OPC 42.5 N PP -E",
        price: 6464646,
      },
      {
        unit: "Bags",
        quantity: 369,
        name: "LUCKY STAR OPC 42.5 N PP -E",
        price: 6464646,
      },
    ],
    total_quantity: 317090,
    total_price: 1472886346044,
    zone: "ZE",
    subzone: "ZE-6",
    date: "Oct 12, 2023",
    order_number: "#97709611991",
    booking_date: "2023-10-12",
    delivery_address: "Hshshxhxhzh",
    contact_number: "1234567890",
    object_id: null,
    email: "ahsansatti502@gmail.com",
    payment_type: "Credit",
    instructions: "Bshsnjxjxjx",
    status: "Pending",
    reason: null,
    is_approved: true,
    booked_by: "full_name",
    outlet: "Depot Cement",
    content_type: null,
    parent: null,
    distributor: 4417,
    market_visit: null,
    higher_market_visit: null,
  };
  function capitalizeKeysAndReplaceUnderscores(obj) {

    if (Array.isArray(obj)) {
      return obj.map((item) => capitalizeKeysAndReplaceUnderscores(item));
    } else if (typeof obj === "object" && obj !== null) {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          newObj[newKey] = capitalizeKeysAndReplaceUnderscores(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj;
    }
  }

  const [history, setHistory] = useState([]);
  const handleDetails = (val) => {
    setForm(initialValue);
    setActionModal(!ActionModal);

    ApiCall(
      "get",
      `workflows/inbox?id=${val.id}`,
      {},
      setActionDetailsAPi,
      null,
      setActionDetailsAPiCount
    ).then((res) => {
      if (res.data[0]?.related_instance?.created_at) {
        res.data[0].related_instance.created_at = new Date(res.data[0].related_instance.created_at).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
      }

      if (res.data[0]?.related_instance?.updated_at) {
        res.data[0].related_instance.updated_at = new Date(res.data[0].related_instance.updated_at).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
      }

      if (res.data[0]?.related_instance?.deleted_at) {
        res.data[0].related_instance.deleted_at = new Date(res.data[0].related_instance.deleted_at).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
      }
      

      if (res.data != undefined) {
        setHistory(res.data[0].approval_history);

        let a = capitalizeKeysAndReplaceUnderscores(
          res.data[0]?.related_instance
        );
        setActionDetails(a);
      }
    });
  };
  const handleFilter = (filter) => {
    if (filter) {
      setFilterValues();
    }
    {
      ApiCall(
        "get",
        ComponentAPI,
        { offset: 0, limit, ...sortBy, ...filter },
        setsubZone,
        setloading,
        setCount
      );

      setFilterValues(filter);
    }
  
  };
  const [modalAll ,setModalAll]=useState(false)
  const [comment ,setcomment]=useState(false)
  const [status ,setStatus]=useState("")

  const onClickApprove=()=>{
    if (checkedList.length < 1) {
      // setSelectedModal(true);
      toast.error(`Please select at least one record to Approve`);
    }
    else
    {
      
      setModalAll(true)
      setStatus("Approved")
      // console.log(checkedList.map((item)=>item?.id),"checkedlist is")
    }
  }
  const onClickReject=()=>{
    if (checkedList.length < 1) {
      // setSelectedModal(true);
      toast.error(`Please select at least one record to Reject`);
    }
    else
    {
      setModalAll(true)
      setStatus("Rejected")

    }
  }
  const handleSubmitAll= (e)=>{
    e.preventDefault();
    const addField={
      id:checkedList?.map((item)=>item?.id),
      status,
      comment,
    }
    ApiCall("update", ComponentAPI, addField).then((res) => {
      if (res != undefined) {
        ApiCall(
          "get",
          ComponentAPI,
          { offset, limit },
          setsubZone,
          setloading,
          setCount
        );
        setModalAll(false)
        setCheckedList([])
  }
})
  }
  return (
    <div className="protected_page_content">
      <div className="page-content">
      {modalAll && (
        <Modal className="" isOpen={modalAll} toggle={()=>{setModalAll(!modalAll)
          setcomment("")}} centered>
          <ModalHeader toggle={()=>{setModalAll(!modalAll)
          setcomment("")}} tag="h4">
            {/* {isupdate ? "Edit " : "Add "} */}
            {/* Approve */}
          </ModalHeader>
          <ModalBody className="">
        
              <div>
                <div>
                  <Row>
                  <Col xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                          <InputCommon
                            name="comment"
                            label="Comment"
                            type="textarea"
                            onChange={(e)=>setcomment(e.target.value)}
                            value={comment || ""}
                            // validationSchema={ va}
                          />
                        </Col>
                  </Row>
                  <div className="w-100 text-center">
                    <button
                      className="btn btn-primary save-user px-5"
                      type="submit"
                      onClick={(e) => handleSubmitAll(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
          
          </ModalBody>
        </Modal>
      )}
        {isFilter && (
          <FilterModal
            show={isFilter}
            onCloseClick={() => setIsFilter(false)}
            countries={countries}
            filterValues={filterValues}
            handleFilter={handleFilter}
            setPreviousFilterValue={setPreviousFilterValue}
            previousFilterValues={previousFilterValues}
          />
        )}
         <TableActionApproved
          checkedData={checkedList}
          isAddNew={false}
          isEdit={false}
          handleApprove={onClickApprove}
          handleReject={onClickReject}
          // offset={offset}
          // handleAddNew={onClickAddNew}
          // handleDelete={onClickDelete}
          // handleEdit={onClickEdit}
          setCount={setCount}
          setloading={setloading}
          isExport={false}
          isActiveInActive={false}

          // onExport={() => downloadExcel("area/sub_zone", "SubZone List")}
        />
        {ActionModal && (
          <Modal
            className="modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable "
            isOpen={ActionModal}
            toggle={toggleActionModal}
          >
            <ModalBody className="modal-body">
              <span
                class="mdi mdi-window-close d-flex justify-content-end "
                onClick={() => setActionModal(!ActionModal)}
              ></span>

              <div className=" " style={{ cursor: "pointer" }}>
                <p
                  style={{ background: "#9F25391A" }}
                  className="fw-bolder p-2"
                >
                  Details
                </p>
              </div>

              <div id="json-container">
                {renderJsonData(ActionDetails)}
                <br className="color-primary" />
                <h6
                  className="text-primary pb-1"
                  style={{ background: "#9F25391A" }}
                >
                  History
                </h6>

                <table className="table table-striped mb-4">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "red",
                        border: "1px solid",
                        background: "#9F25391A",
                      }}
                    >
                      <th
                        style={{ border: "1px solid #ced4da", padding: "8px" }}
                      >
                        Sr No.
                      </th>
                      <th
                        style={{ border: "1px solid #ced4da", padding: "8px" }}
                      >
                        User
                      </th>
                      <th
                        style={{ border: "1px solid #ced4da", padding: "8px" }}
                      >
                        Comment
                      </th>
                      <th
                        style={{ border: "1px solid #ced4da", padding: "8px" }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.length !== 0 ? (
                      history.map((item, index) => {
                        return (
                          <tr style={{ border: "1px solid #ced4da" }}>
                            <td style={{ border: "1px solid #ced4da" }}>
                              {index + 1}
                            </td>
                            <td style={{ border: "1px solid #ced4da" }}>
                              {item?.user}
                            </td>
                            <td style={{ border: "1px solid #ced4da" }}>
                              {item?.comment}
                            </td>
                            <td style={{ border: "1px solid #ced4da" }}>
                              {item?.status == "Approved" ? (
                                <Badge
                                  color="success"
                                  style={{ width: "60px" }}
                                >
                                  Approved
                                </Badge>
                              ) : item?.status == "Rejected" ? (
                                <Badge color="danger" style={{ width: "60px" }}>
                                  Rejected
                                </Badge>
                              ) : (
                                item?.status
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr style={{ border: "1px solid #ced4da" }}>
                        <td>No History found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <br />
              <div className="mt-2">
                <Label className="text-primary fw-bolder">
                  Add a Comment *
                </Label>

                <textarea
                  className="p-1"
                  placeholder="Comment Here"
                  value={form.comment}
                  name="comment"
                  onChange={(e) => handletextChange(e)}
                  rows="4"
                  style={{
                    display: "block",
                    minWidth: "100%",
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                  }}
                ></textarea>
              </div>

              <div className="mt-3 d-flex justify-content-end">
                <Button
                  className="me-3"
                  color="primary"
                  onClick={() => SubmitAction({ ValueOfAction: "Accept" })}
                >
                  Accept
                </Button>
                <Button
                  color="secondary"
                  onClick={() => SubmitAction({ ValueOfAction: "Rejected" })}
                >
                  Reject
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
        {modal && (
          <Modal
            className="modal modal-dialog modal-dialog-centered h-100"
            isOpen={modal}
            toggle={toggle}
            backdrop={isEdit ? undefined : "static"}
          >
            <ModalBody className="modal-body  ">
              <div>
                <div
                  className="d-flex justify-content-between "
                  style={{ cursor: "pointer" }}
                >
                  <Label className="text-primary fw-bolder">
                    Add a Comment *
                  </Label>
                  <span
                    class="mdi mdi-window-close"
                    onClick={() => toggle()}
                  ></span>
                </div>

                <textarea
                  value={form.comment}
                  name="comment"
                  onChange={(e) => handletextChange(e)}
                  rows="4"
                  style={{ display: "block", minWidth: "100%" }}
                ></textarea>
              </div>

              <div className="mt-3 d-flex justify-content-end">
                <Button
                  className="me-3"
                  color="primary"
                  onClick={() => SubmitAction({ ValueOfAction }, "modal")}
                >
                  {ValueOfAction}
                </Button>
                <Button color="secondary" onClick={() => toggle()}>
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
        <Container fluid>
          <Breadcrumbs
            title="Work Flow  Management "
            // onFilter={(e) => {
            //   setSelectedCategory(e);
            //   handleFilterByCategory(e);
            // }}
            breadcrumbItem="Global Approvals"
          />

          <TableActionHeader
            limit={limit}
            onEditColumn={() => setIsEditColumn(true)}
            isEditColumn={false}
            onSetLimit={(e) => onChangeLimit(e)}
            handleSearch={(e) => handleSearch(e)}
            isFilter={true}
            handleFilter={onShowFilter}
            previousFilterValues={previousFilterValues}
            handleClearFilter={handleClearFilter}
          />

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  <div style={{ overflowX: "auto" }}>
                    <nav>
                      <div
                        style={{
                          width: "calc(100vw - 20.5rem) ",
                          flexWrap: "nowrap",
                          overflowX: "auto",
                          overflowY: "hidden",
                        }}
                        className="nav nav-tabs GlobalApprovalsTabs scrollable-container"
                        id="nav-tab"
                        role="tablist"
                      >
                        {tabObj.map((index) => {
                          return (
                            <button
                              style={{ whiteSpace: "nowrap" }}
                              class={
                                activeTab == index.name
                                  ? "nav-link active"
                                  : "nav-link "
                              }
                              onClick={() => handleActionTabClicl(index)}
                              id={`nav-${index.name}-tab`}
                              data-bs-toggle="tab"
                              data-bs-target={`#nav-${index.name}`}
                              type="button"
                              role="tab"
                              aria-controls={`nav-${index.name}`}
                              aria-selected="true"
                            >
                              {index.name}{" "}
                              {index.notifactions != "0" ? (
                                <Badge color="success">
                                  {index.notifactions}
                                </Badge>
                              ) : null}
                            </button>
                          );
                        })}
                      </div>
                    </nav>
                  </div>
                  <TableContainer
                    columns={columns}
                    data={subZones}
                    isGlobalFilter={false}
                    className="custom-header-css"
                    offset={offset}
                    count={count}
                    isColumnEdit={isEditColumn}
                    toggleEdit={() => setIsEditColumn(!isEditColumn)}
                    onCustomNextPage={() => onHandleCustomPage(offset, count)}
                    onHandleBackCustomPage={() =>
                      onHandleBackCustomPage(offset, count)
                    }
                    customPageSize={limit}
                    handleSortBy={(sort_by) => handleSortBy(sort_by)}
                    setShowDetails={() => setShowDetails(true)}
                    setSelectedRowData={(data) => setSelectedRowData(data)}
                  />
                </>
              )}
            </div>
            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              ...
            </div>
            <div
              class="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              ...
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default GlobalApprovals;
