export const handleSelectAllCommon = (func,checkedList ,isSelectAll , setCheckedList ,setIsSelectAll ) => {
        
    if (
        func.length === checkedList.length &&
        checkedList.length > 0 &&
        isSelectAll
    ) {
        setCheckedList([]);
        setIsSelectAll(false);
    } else {
        setCheckedList(func);
        setIsSelectAll(true);
    }
};
