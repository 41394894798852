// Products
import HttpService from "./HttpService";

export const getBulk = async (param) => {
  // console.log("offset: ", offset);
  const axiosApi = new HttpService();
  const { data } =
    param?.offset === "all"
      ? await axiosApi.get(param.api)
      : await axiosApi.get(param.api, {
          params: param,
        });
  return data?.data;
};



// export const addBulk = async (brands) => {
//   const axiosApi = new HttpService();
//   const { data } = await axiosApi.post("/market_intelligence/brand", brands);
//   return data?.data;
// };
// export const deleteBulk = async (brands) => {
//   const axiosApi = new HttpService();
//   const { data } = await axiosApi.delete(
//     "/market_intelligence/brand?id=" + [0, ...brands]
//   );
//   return data?.data;
// };
export const updateBulk = async (bulk) => {
  const axiosApi = new HttpService();
  
  const { data } = await axiosApi.patch(`/bulk_update/?feature=${bulk.module}` , bulk.params)
  return data?.data;
};


export const updateBulkImportExportWidget = async (bulk) => {
  const axiosApi = new HttpService();
  
  const { data } = await axiosApi.patch(`/bulk_update/?feature=${bulk.module}` , bulk.params)
  return data?.data;
};
