import React from "react";
import { useHistory } from "react-router-dom";
import { TableIcon, ActionLinkButton, VerticalLine } from "../../../Common/StyledComponents";
import { toast } from "react-toastify";
// import BulkUpdate from "../../../../Pages/SystemAdmin/BulkUpdate";
import { updateBulk } from "../../../Common/BulkUpdateApi";
import { ApiCall } from "../../../Common/CommonCallApi";

const TableActionApproved = ({
  buttonText,
  isAddLimit = false,
  handleAddLimit,
  launchButton,
  handleLaunch,
  handleAddNew,
  isProductLaunch = false,
  handleApprove,
  handleReject,
  handleEdit,
  checkedData = [],
  showDeleteButton = true,
  isExport = true,
  isAddNew = "true",
  onExport,
  activeButton,
  handleStatus = () => {},
  isActive = false,
  isBulk,
  onBulkUpdate = () => {},
  ComponentAPI,
  isVerticalLine=true,
  setCheckedList,
  setFunc,
  isEdit = true,
  setCount,
  setloading,
  offset = 0,
  limit = 10,
  isChangeStatusPopUp = false,
  isChangeStatusPopUpClickFunc = false,
  isActiveInActive = true,
  is_published = "",
}) => {
  const areAllIsPublishedValuesEqual = () => {
    if (checkedData.length === 0) {
      return false; // No objects to compare, so they are technically equal.
    } else {
      const firstIsPublishedValue = checkedData[0]?.is_published;

      for (let i = 1; i < checkedData.length; i++) {
        if (checkedData[i].is_published !== firstIsPublishedValue) {
          return false; // If any object has a different is_published value, return false.
        }
      }

      return true; // All objects have the same is_published value.
    }
  };
  const handleShowPublishUnpublish = () => {
    if (ComponentAPI == "notifications/email") {
      let ab = areAllIsPublishedValuesEqual();
      if (ab) {
        if (checkedData[0].is_published == !true) {
          return (
            <>
              <ActionLinkButton
                color="link"
                // className="btn mb-2 m"
                onClick={() => handlePublish()}
              >
                <i className="mdi mdi mdi-publish mx-1 font-size-1rem" />
                Publish
              </ActionLinkButton>
            </>
          );
        } else {
          return (
            <>
              <ActionLinkButton
                color="link"
                // className="btn mb-2 m"
                onClick={() => handleUnPublish()}
              >
                <i className="mdi mdi-publish-off mx-1 font-size-1rem" />
                Unpublished
              </ActionLinkButton>
            </>
          );
        }
      } else {
        return <></>;
      }
    }
  };

  const handleUnPublish = () => {
    if (checkedData.length >= 1) {
      let bulk = {
        module: ComponentAPI,
        params: {
          id: checkedData.map((index) => index.id),
          is_published: false,
        },
      };
      updateBulk(bulk).then((res) => {
        if (res) {
          ApiCall(
            "get",
            ComponentAPI,
            { offset, limit },
            setFunc,
            setloading,
            setCount
          ).then((res) => {
            toast.success("Record Updated Successfully");
          });
          setCheckedList([]);
        }
      });
    } else {
      toast.error("Select Atleast One Record To Perform This Action");
    }
  };

  const isActionButtonFunction = () => {};
  const handlePublish = () => {
    if (checkedData.length >= 1) {
      let bulk = {
        module: ComponentAPI,
        params: {
          id: checkedData.map((index) => index.id),
          is_published: true,
        },
      };
      updateBulk(bulk).then((res) => {
        if (res) {
          ApiCall(
            "get",
            ComponentAPI,
            { offset, limit },
            setFunc,
            setloading,
            setCount
          ).then((res) => {
            toast.success("Record Updated Successfully");
          });
          setCheckedList([]);
        }
      });
    } else {
      toast.error("Select Atleast One Record To Perform This Action");
    }
  };
  const handleActive = () => {
    if (checkedData.length >= 1) {
      let checkedids;
      if (ComponentAPI == "/user") {
        checkedids = checkedData.map((index) => index.guid);
      } else {
        checkedids = checkedData.map((index) => index.id);
      }
      const hasQuestionMark = ComponentAPI.indexOf('?') !== -1;
      if(hasQuestionMark){
      const questionMarkIndex = ComponentAPI.indexOf('?');
        const questionMarkApi=ComponentAPI.substring(0, questionMarkIndex)
        let bulk = {
          module: questionMarkApi,
          params: { id: checkedids, is_active: "ACTIVE" },
        };
        updateBulk(bulk).then((res) => {
          if (res) {
            ApiCall(
              "get",
              ComponentAPI,
              { offset, limit },
              setFunc,
              setloading,
              setCount
            ).then((res) => {
              toast.success("Record Updated Successfully");
            });
            setCheckedList([]);
          }
        });
      }else{
        let bulk = {
          module: ComponentAPI,
          params: { id: checkedids, is_active: "ACTIVE" },
        };
        updateBulk(bulk).then((res) => {
          if (res) {
            ApiCall(
              "get",
              ComponentAPI,
              { offset, limit },
              setFunc,
              setloading,
              setCount
            ).then((res) => {
              toast.success("Record Updated Successfully");
            });
            setCheckedList([]);
          }
        });
      }

    } else {
      toast.error("Select Atleast One Record To Perform This Action");
    }
  };
  if (ComponentAPI == "notifications/email") {
  }
  const handleInActive = () => {
    if (checkedData.length >= 1) {
      let checkedids;
      if (ComponentAPI == "/user") {
        checkedids = checkedData.map((index) => index.guid);
      } else {
        checkedids = checkedData.map((index) => index.id);
      }
      const hasQuestionMark = ComponentAPI.indexOf('?') !== -1;

      if(hasQuestionMark){
        const questionMarkIndex = ComponentAPI.indexOf('?');
        const questionMarkApi=ComponentAPI.substring(0, questionMarkIndex)
        debugger
        let bulk = {
          module: questionMarkApi,
          params: { id: checkedids, is_active: "INACTIVE" },
        };
        updateBulk(bulk).then((res) => {
          if (res) {
            ApiCall(
              "get",
              ComponentAPI,
              { offset, limit },
              setFunc,
              setloading,
              setCount
            ).then((res) => {
              toast.success("Record Updated Successfully");
            });
            setCheckedList([]);
          }
        });
      }
      else{
        let bulk = {
          module: ComponentAPI,
          params: { id: checkedids, is_active: "INACTIVE" },
        };
        updateBulk(bulk).then((res) => {
          if (res) {
            setCheckedList([]);
            ApiCall(
              "get",
              ComponentAPI,
              { offset: offset, limit: limit },
              setFunc,
              setloading,
              setCount
            ).then((res) => {
              toast.success("Record Updated Successfully");
            });
          }
        });
      }
  
    } else {
      toast.error("Select Atleast One Record To Perform This Action");
    }
  };

  // const history = useHistory();
  return (
    <div className="TableHeaderFixed">
      <div className="mr-15">
        <TableIcon
          // onClick={() => history.goBack()}
          className="mdi mdi-arrow-left me-3"
        />
        {isVerticalLine  && <VerticalLine />}
        {isAddNew === "true" ? (
          <ActionLinkButton color="link" onClick={handleAddNew}>
            <i className="mdi mdi-plus mx-1 font-size-1rem" />
            Add New
          </ActionLinkButton>
        ) : null}
        {activeButton ? null : showDeleteButton ? (
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={handleApprove}
          >
            <i className="mdi mdi-check font-size-1rem mx-1" />
            Approve All
          </ActionLinkButton>
        ) : null}
                {activeButton ? null : showDeleteButton ? (
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={handleReject}
          >
            <i className="mdi mdi-close font-size-1rem mx-1" />
            Reject All
          </ActionLinkButton>
        ) : null}
        {launchButton && (
          <ActionLinkButton color="link" onClick={handleLaunch}>
            <i className="mdi mdi-plus mx-1 font-size-1rem" />
            Launch
          </ActionLinkButton>
        )}
        {isProductLaunch != true && <VerticalLine />}
        {checkedData.length === 1 &&
          (activeButton ? (
            <ActionLinkButton color="link" onClick={handleStatus}>
              {isActive ? (
                <>
                  <i className="mdi mdi-eye-off-outline mx-1 font-size-1rem" />
                  Deactivate
                </>
              ) : (
                <>
                  <i className="mdi mdi-eye-outline mx-1 font-size-1rem" />
                  Active
                </>
              )}
            </ActionLinkButton>
          ) : null)}
        {checkedData.length > 1 || isEdit == false ? null : (
          <ActionLinkButton color="link" onClick={handleEdit}>
            <i className="mdi mdi-square-edit-outline mx-1 font-size-1rem" />
            Edit
          </ActionLinkButton>
        )}
        {checkedData.length > 1 || isAddLimit == false ? null : (
          <>
                    {isVerticalLine  && <VerticalLine />}
            <ActionLinkButton color="link" onClick={handleAddLimit}>
              <i className="mdi mdi-plus mx-1 font-size-1rem" />
              {buttonText}
            </ActionLinkButton>
          </>
        )}
        {isExport == true ? (
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={onExport}
          >
            <i className="mdi mdi-file-export-outline mx-1 font-size-1rem" />
            Export
          </ActionLinkButton>
        ) : null}
        {isActiveInActive == true ? (
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={() => handleActive()}
          >
            <i className="mdi mdi-account-badge-outline mx-1 font-size-1rem" />
            Active
          </ActionLinkButton>
        ) : null}

        {isActiveInActive == true ? (
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={() => handleInActive()}
          >
            <i className="mdi mdi-account-cancel-outline mx-1 font-size-1rem" />
            InActive
          </ActionLinkButton>
        ) : null}

        {checkedData.length > 1 || isChangeStatusPopUp == false ? null : (
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={isChangeStatusPopUpClickFunc}
          >
            <i className="mdi mdi-list-status font-size-1rem mx-1" />
            Update Status
          </ActionLinkButton>
        )}

        {Array.isArray(checkedData) && handleShowPublishUnpublish()}

        {/* 


        {ComponentAPI == "notifications/email" ? (
         isActionButtonFunction() &&  
         
         
         <ActionLinkButton
          color="link"
          // className="btn mb-2 m"
          onClick={() => handlePublish()}
        >
          <i className="mdi mdi mdi-publish mx-1 font-size-1rem" />
          Publish
        </ActionLinkButton>
      
        ) : null}
        {ComponentAPI == "notifications/email" ? (

is_published==true || is_published=='' && 
          <ActionLinkButton
            color="link"
            // className="btn mb-2 m"
            onClick={() => handleUnPublish()}
          >
            <i className="mdi mdi-publish-off mx-1 font-size-1rem" />
            Unpublished
          </ActionLinkButton>
        ) : null} */}
      </div>
      <hr className="hrsm" />
    </div>
  );
};

export default React.memo(TableActionApproved);
