import axios from "axios";
import { toast } from "react-toastify";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const postLogin = async (data, config = {}) => {
  console.log(`${REACT_APP_BASE_URL}/login`, "loginApiWearehittingIs this");
  try {
    let response = await axios.post(
      `${REACT_APP_BASE_URL}/login`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=UTF-8,multipart/form-data",

          Accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const postForgetPassword = async (data, config = {}) => {
  try {
    let response = await axios.post(
      `${REACT_APP_BASE_URL}/forget-password`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=UTF-8,multipart/form-data",

          Accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const postResetPassword = async (data, config = {}) => {
  let response = await axios.post(
    `${REACT_APP_BASE_URL}/verify_otp`,
    {
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/json; charset=UTF-8,multipart/form-data",

        Accept: "application/json",
      },
    }
  );

  return response.data;
};
