import React, { useState, useEffect } from "react";

import { Col, Row, FormFeedback, Form, Label, Input } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { SelectCommon } from "../../../../Common/FormCommonFormik";
// import { useDispatch, useSelector } from 'react-redux';
// import { getBrands } from '../../../store/actions';
import { ApiCall } from "../../../../Common/CommonCallApi";

function ProductsBulk({ SaveButton }) {
  // const { brands } = useSelector((state) => state.brands);
  const [brands, setBrands] = useState([]);

  const [product, setProduct] = useState();

  // const dispatch=useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      is_active: product?.is_active || "",
      type:
        (product && {
          value: product?.type || "",
          label: product?.type || "",
        }) ||
        "",
      category:
        (product && {
          value: product?.category || "",
          label: product?.category === "IL" ? "Internal" : "External" || "",
        }) ||
        "",
      manufacturer:
        (product && {
          value: product?.manufacturer?.id || "",
          label: product?.manufacturer?.name || "",
        }) ||
        "",
    },

    onSubmit: (values) => {
      const newProduct = {
        category: values["category"].value,
        type: values["type"].value,
        manufacturer: values["manufacturer"].value,
        is_active: values["is_active"],
      };
      // save new user
      console.log(newProduct, "newProduct");
      SaveButton(newProduct);
    },
  });

  const handleRadioButtons = (e) => {
    validation.setFieldValue("is_active", e.target.value);
  };
  useEffect(() => {
    if (brands.length <= 0) {
      // dispatch(getBrands('all'))
      ApiCall(
        "get",
        "/market_intelligence/brand",
        { is_active: "ACTIVE" },
        setBrands
      );
    }
  }, []);

  return (
    <div >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row style={{minHeight: "61vh"}}>
          <Col xs={6}>
            <Col xs={12}>
              <label className="me-2 fw-bold">
                Status <span style={{ color: "#dc3545;" }}>*</span>{" "}
              </label>
              <br />
              <div className="d-flex">
                <input
                  type="radio"
                  name="is_active"
                  value=""
                  onChange={(e) => handleRadioButtons(e)}
                />
                <label className="ms-2"> Default</label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  name="is_active"
                  value="ACTIVE"
                  onChange={(e) => handleRadioButtons(e)}
                />
                <label className="ms-2"> Active</label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  name="is_active"
                  value="INACTIVE"
                  onChange={(e) => handleRadioButtons(e)}
                />{" "}
                <label className="ms-2"> InActive</label>
              </div>
            </Col>
            <div className="">
              <SelectCommon
                validation={validation}
                name="manufacturer"
                label="Manufacturer"
                options={brands.map((brand) => ({
                  value: brand.id,
                  label: brand.name,
                }))}
              />
            </div>
          </Col>

          <Col lg={6} xs={12} style={{ marginTop: "30px" }}>
            <div className="mb-3">
              <Label className="form-label">Category</Label>
              <Select
                id="category"
                name="category"
                placeholder="Select Category"
                onBlur={() => validation.setFieldTouched("category", true)}
                value={validation.values.category}
                onChange={(option) =>
                  validation.setFieldValue("category", option)
                }
                options={[
                  {
                    value: "IL",
                    label: "Internal",
                  },
                  {
                    value: "EL",
                    label: "External",
                  },
                ]}
                // formatCreateLabel={(inputText) => `${inputText}`}
              />
            </div>

            {/* <InputCommon type="file"  label='Images' isRequired='true' placeholder="Images" name='images' accept="image/x-png,image/gif,image/jpeg"  validation={validation} /> */}
          </Col>
        </Row>
        <button
          type="submit"
          className="btn btn-primary save-user"
          style={{ float: "right", marginTop: "1rem", width: "95px" }}
        >
          Save
        </button>
      </Form>
    </div>
  );
}

export default ProductsBulk;
