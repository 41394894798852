import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BulkUpdateConfirmation from "../../../Common/BulkUpdateConfirmation";

import Loader from "../../../Common/Loader";
import { updateBulk } from "../../../Common/BulkUpdateApi";
import BrandBulk from "./BulkForms/BrandBulk";
import CombinedForm from "./BulkForms/CombinedForm";
import DesignationBulk from "./BulkForms/DesignationBulk";
import EmployFormBulk from "./BulkForms/EmployFormBulk";
import NotificationBulk from "./BulkForms/NotificationBulk";
import OutletBulk from "./BulkForms/OutletBulk";
import PlantBulk from "./BulkForms/PlantBulk";
import ProductsBulk from "./BulkForms/ProductsBulk";
import WarehouseBulk from "./BulkForms/WarehouseBulk";

function Step3({
  selectedModule,
  TablecheckedList,
  setValue,
  setSelectedModule,
  setTableCheckedList,
}) {
  const [formValue, setformValue] = useState("");
  const [loading, isLoading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  console.log("TablecheckedList page 3", TablecheckedList);
  const SaveButton = (BulkUpdateForm) => {
    const as = clean(BulkUpdateForm);
    function clean(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === "" ||
          obj[propName] === undefined
        ) {
          delete obj[propName];
        }
      }
      return obj;
    }
    SaveFinal(as);
  };

  const SaveFinal = (as) => {
    let a = selectedModule;
    let value;
    let params;
    let length;
    if (selectedModule == "user") {
      let id = TablecheckedList.map((index) => index.id);
      params = { id, ...as };
      value = { module: a, params };
      length = Object.keys(value.params).length;
    } else {
      let id = TablecheckedList.map((index) => index.id);
      params = { id: id, ...as };
      value = { module: a, params };
      length = Object.keys(value.params).length;
    }

    if (length > 1) {
      setformValue(value);
      toogleModal();
    } else {
      toast.warning("Atleast One field is required to update ");
      isLoading(false);
    }
  };

  const toogleModal = () => {
    setshowModal(!showModal);
  };

  const ApiCall = async () => {
    try {
      const data = await updateBulk(formValue)
        .then((res) => {
          if (res) {
            toast.success("Bulk Record Updated Successfully");
            setValue(1);
            setSelectedModule("");
            setTableCheckedList([]);
          }
        })
        .catch((err) => {
          console.log(err, "error");
          toogleModal();
          toast.error(err?.response?.messege || "Something went wrong");
          isLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const Form = () => {
    switch (selectedModule) {
      case "area/region":
      case "area/country":
      case "area/city":
      case "area/zone":
      case "area/sub_zone":
      case "user":
        return <CombinedForm SaveButton={SaveButton} department={false} />;
      case "department":
        return <CombinedForm department="true" SaveButton={SaveButton} />;
      case "employee":
        return <EmployFormBulk SaveButton={SaveButton} />;
      case "designation":
        return <DesignationBulk SaveButton={SaveButton} />;
      case "channel/outlet?api_type=list":
        return <OutletBulk SaveButton={SaveButton} />;
      case "/market_intelligence/brand":
        return <BrandBulk SaveButton={SaveButton} />;
      case "/inventory/product":
        return <ProductsBulk SaveButton={SaveButton} />;
      case "/inventory/plant":
        return <PlantBulk SaveButton={SaveButton} />;
      case "/inventory/warehouse":
        return <WarehouseBulk SaveButton={SaveButton} />;
      case "notifications/email":
        return <NotificationBulk SaveButton={SaveButton} />;
      default:
        break;
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      {showModal && (
        <BulkUpdateConfirmation
          showModal={showModal}
          ConfirmSave={ApiCall}
          toggleModal={toogleModal}
        />
      )}

      <Form />
    </div>
  );
}

export const MemoizedStep3 = React.memo(Step3);
