import React, { useState } from "react";
import { useMemo } from "react";
import TableContainer from "../../../Common/TableWithOutPagination";

function Step2({
  data,
  columnArrays,
  setTableCheckedList,
  TablecheckedList,
  user = "false",
}) {
  const [isSelectAll, setIsSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (
      data?.data.length === TablecheckedList.length &&
      TablecheckedList.length > 0 &&
      isSelectAll
    ) {
      setTableCheckedList([]);
      setIsSelectAll(false);
    } else {
      setTableCheckedList(data?.data);
      setIsSelectAll(true);
    }
  };

  const handleChecked = (item) => {
    debugger;
    let temp = [...TablecheckedList];
    if (user == "true") {
      if (temp.find((data) => data.guid === item?.guid)) {
        temp = temp.filter((val) => {
          return val?.guid !== item?.guid;
        });
      } else {
        temp.push(item);
      }
    } else {
      if (temp.find((data) => data.id === item?.id)) {
        temp = temp.filter((val) => {
          return val?.id !== item?.id;
        });
      } else {
        temp.push(item);
      }
    }
    setIsSelectAll(false);
    setTableCheckedList(temp);
  };

  const column = useMemo(() => {
    const dynamicCols = columnArrays.map((col) => {
      return {
        Header:
          col.key.charAt(0).toUpperCase() +
          col.key.slice(1).replace("_", " ").toUpperCase(),
        accessor: col.key.toLowerCase(),
        Cell: (cellProps) => (
          <>
            {col.type == "object"
              ? cellProps.row.original[col.key.toLowerCase()]?.name || cellProps.row.original[col.key.toLowerCase()]?.first_name || cellProps.row.original[col.key.toLowerCase()]?.prefix
              : cellProps.row.original[col.key.toLowerCase()]}
          </>
        ),
      };
    });
    const checkboxCol = {
      Header: () => {
        return (
          <input
            readOnly
            checked={data.data.length === TablecheckedList.length}
            onClick={() => handleSelectAll()}
            type="checkbox"
            className="form-check-input"
          />
        );
      },
      accessor: "checkbox",
      Cell: (cellProps) => {
        return (
          <input
            type="checkbox"
            readOnly
            checked={
              user === "true"
                ? TablecheckedList.filter(
                    (emp) => emp?.guid === cellProps.row.original.guid
                  ).length > 0
                : TablecheckedList.filter(
                    (emp) => emp?.id === cellProps.row.original.id
                  ).length > 0
            }
            onClick={(event) => {
              event.stopPropagation();
              handleChecked(cellProps.row.original);
            }}
            className="form-check-input"
          />
        );
      },
    };
    console.log([checkboxCol, ...dynamicCols]);
    return [checkboxCol, ...dynamicCols];
  }, [TablecheckedList.length, isSelectAll]);

  return (
    <div className="BulkUpdate">
      <TableContainer
        columns={column}
        data={data?.data}
        isGlobalFilter={false}
        customPageSize={data?.count}
        className="custom-header-css"
      />
    </div>
  );
}

export const MemoizedStep2 = React.memo(Step2);
