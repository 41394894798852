import { ApiCall } from "./CommonCallApi";

export const onChangeLimitCommon = (api,e ,setLimit ,setOffset ,sortBy,filterValues,searchKeyWord,setfunc,setloading,setCount) => {
    setLimit(parseInt(e, 10));
    setOffset(0);
        ApiCall('get',api , { 
        offset: 0,
        limit:e,
        ...sortBy,
        ...filterValues,
        ...searchKeyWord,
      } ,setfunc,setloading,setCount )

    // forceUpdate();
  };