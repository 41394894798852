import React from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Table,
  Label,
  Badge,
} from "reactstrap";

const DetailModal = ({
  show,
  dataArray,
  title,
  onCloseClick,
  tag,
  SystemLogs = false,
  logs = [],
  role = false,
  features_list = [],
  modules_list,
  isOutlet,
  images = [],
  verifiedOuteIstrue = false,
}) => {
  debugger;
  return (
    <Modal className="modal-right h-100" isOpen={show} toggle={onCloseClick}>
      <ModalHeader toggle={onCloseClick} tag="h5">
        {title}
      </ModalHeader>
      <ModalBody className="py-3 px-0">
        {dataArray.map((data, index) => (
          <Row className="px-3 " key={index}>
            <Col xs={12}>
              <label className=" mt-2">{data?.label}</label>
              <input className="form-control" disabled value={data?.value} />
            </Col>
          </Row>
        ))}

        <Row className="ps-3 pe-3 mt-2">
          <Col>
            {role == true && (
              <>
                <label className=" mt-2">Roles Assigned</label>

                <Table responsive bordered striped>
                  <thead>
                    <tr>
                      <th style={{ minWidth: "7rem" }}>Features</th>
                      <th>Permission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {features_list.map((index, val) => {
                      return (
                        <>
                          <tr key={val}>
                            <td>{index.feature?.name}</td>{" "}
                            <td>
                              {index.permissions.map((ind, illi) => (
                                <Badge
                                  className="me-2"
                                  key={illi}
                                  color="success"
                                >
                                  {ind?.name}
                                </Badge>
                              ))}
                            </td>{" "}
                            <td>{index.previous_value}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
        <Row className="ps-3 pe-3 mt-2">
          {" "}
          {SystemLogs && (
            <Col xs={12}>
              <Label>Changes Detail</Label>
              <Table responsive bordered striped>
                <thead>
                  <tr>
                    <th style={{ minWidth: "7rem" }}>Field Name</th>
                    <th>New Value</th>
                    <th>Previous Value</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((index, val) => {
                    return (
                      <>
                        <tr key={val}>
                          <td>{index.field}</td> <td>{index.value}</td>{" "}
                          <td>{index.previous_value}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>

        {isOutlet && (
          <Row className="ps-3 pe-3 mt-2">
            {images?.map((imge) => {
              return (
                <img
                  className="mb-3"
                  src={imge?.evidence}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="Image Loading Error"
                />
              );
            })}
          </Row>
        )}
        {verifiedOuteIstrue && (
          <Row className="ps-3 pe-3 mt-2">
            {images.map((imge) => {
              return (
                <img
                  className="mb-3"
                  src={imge?.image}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="Image Loading Error"
                />
              );
            })}
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DetailModal;
