import React from 'react'
import CommingSoon from "../Assets/ComingSoon.png"

function UnderConstruction() {
  return (
    <div>
      <img style={{height:'80vh' ,minWidth:'80vw'} }src={CommingSoon} alt='NewUser' />
    </div>
  )
}

export default UnderConstruction
