import { toast } from "react-toastify";
import Swal from "../../node_modules/sweetalert2/dist/sweetalert2.all.min.js";
import { ApiCall } from "./CommonCallApi";
import "../../src/Assets/css/main.css";

export const onClickDeleteCommon = (
  api,
  func,
  setfunc,
  setloading,
  setCount,
  limit,
  offset,
  setOffset,
  checkedList,
  setCheckedList,
  setIsSelectAll,
  apiChange
) => {
  if (checkedList.length != 0) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",

      showCancelButton: true,
      confirmButtonColor: "#9d242d",
      cancelButtonColor: "#938d8d",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        icon: "swal-icon-size", // Apply the custom CSS class to the icon
      },
    }).then((result) => {
      if (result.isConfirmed) {
        ApiCall(
          "delete",

          api,
          api === "user"
            ? {
                delete: checkedList.map((data) => data?.guid),
              }
            : { delete: checkedList.map((data) => data?.id) }
        ).then((res) => {
          if (res != undefined) {
            console.log("data deleted", res);
            ApiCall(
              "get",
              apiChange ? apiChange : api,

              { offset: 0, limit },
              setfunc,
              setloading,
              setCount
            ).then(() => {
              setOffset(0);
              setCheckedList([]);
            });
          } else {
            console.log("delete");
            Swal.fire("Unsuccessfull!", "Failed to Delete Record ", "error");
          }
        });
      }
    });
    const value =
      offset === 0
        ? 0
        : func.length % limit < 1 + checkedList.length
        ? offset - limit
        : offset;

    setOffset(value);
    //  setCheckedList([]);

    setIsSelectAll(false);
  } else {
    toast.error("Please Select Atleast One Item");
  }
};
