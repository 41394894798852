import React from "react";
import { Container } from "reactstrap";
import BulkUpdateStepper from "./BulkUpdateStepper";

const BulkUpdate = () => {
  document.title = "Bulk Update | Lucky Cement";

  return (
    <div className="protected_page_content">
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Designation" breadcrumbItem="Designation" /> */}
          <BulkUpdateStepper />
        </Container>
      </div>
    </div>
  );
};

export default BulkUpdate;
