import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Input, Modal, ModalBody, Row } from "reactstrap";

const BulkUpdateConfirmation = ({ showModal, ConfirmSave, toggleModal }) => {
  // const [answer, setAnswer] = useState("");

  return (
    <Modal
      isOpen={showModal}
      style={{ maxWidth: '22%' }}
      toggle={toggleModal}
      centered={true}
      backdrop="static"
    >
      <ModalBody className="py-3 px-5" >
        <Row>
          <Col lg={12}>
            <div className="text-center">
              {/* <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              /> */}
              <h4 className = "bulk_modal">Are you sure?</h4>
              <h6 className="bulk_data">{"You won't be able to revert this!"}</h6>
              <p style = {{fontSize:"12px"}}>This is irreversible Action. Are you Sure to Update it </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
              style = {{fontSize:"12px"}}
                type="button"
                className="btn btn-success btn-lg ms-2 "
                onClick={ConfirmSave}
              >
                Yes, Update it!
              </button>
              <button
               style = {{fontSize:"12px"}}
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default BulkUpdateConfirmation;
