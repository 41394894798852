import { ApiCall } from "./CommonCallApi";

export const onHandleCustomPageCommon = (
  api,
  off,
  cou,
  limit,
  sortBy,
  searchKeyWord,
  setFunc,
  setloading,
  setCount,
  setOffset,
  setCheckedList,
  setIsSelectAll,
  filterValues = {}
) => {
  // initialRender ? setInitialRender(false) : null;
  if (cou > off + limit) {
    ApiCall(
      "get",
      api,
      {
        offset: off + limit,
        limit,
        ...sortBy,
        ...searchKeyWord,
        ...filterValues,
      },
      setFunc,
      setloading,
      setCount
    );

    // onPaginationPageChange(parseInt(count / limit + 1));
    setOffset(off + limit);
    setCheckedList([]);
    setIsSelectAll(false);
  }
};
