import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { loadOptions } from "../../../Common/optionRequest";

const FilterModal = ({
  show,
  onCloseClick,

  countries,
  filterValues,
  handleFilter,
  setPreviousFilterValue,
  previousFilterValues,
}) => {
  // Declare state variables for selected regions and error status
  const [selectedCountries, setSelectedCountries] = useState(
    (previousFilterValues && previousFilterValues?.selectedRegions) || []
  );

  const [date_from, setFromDate] = useState(
    (previousFilterValues && previousFilterValues?.from) || null
  );
  const [date_to, setToDate] = useState(
    (previousFilterValues && previousFilterValues?.to) || null
  );
  const [SelectedDate, setSelectedDate] = useState(
    (previousFilterValues && previousFilterValues?.SelectedDate) || null
  );
  const [selectedStatus, setSelectedStatus] = useState(
    (previousFilterValues && previousFilterValues?.status) || null
  );
  const [selectedAction, setSelectedAction] = useState(
    (previousFilterValues && previousFilterValues?.action) || null
  );

  const handleDateChange = () => {
    if (new Date(date_from) > new Date(date_to)) {
      setError("Start date must be less than end date.");
    } else {
      setError(false);
    }
  };

  const [isError, setError] = useState(false);

  console.log("selected", filterValues);

  // Handle click event for the "Apply Filter" button
  const onApply = () => {
    // Call the parent component's filter handler function with selected regions
    handleFilter({
      initiated_by: selectedCountries?.value,

      date_from: date_from,
      date_to: date_to,
      date: SelectedDate,
      status:selectedStatus?.value,
      action:selectedAction?.value
    });

    // Close the modal and save the current filter values for next use
    onCloseClick();
    setPreviousFilterValue({
      selectedCountries: selectedCountries,

      date_from: date_from,
      date_to: date_to,
      date: SelectedDate,
      status:selectedStatus?.value,
      action:selectedAction?.value


    });
  };

  // Render the filter modal component
  return (
    <Modal
      backdrop="static"
      className="modal-right h-100"
      isOpen={show}
      toggle={onCloseClick}
    >
      <ModalHeader tag="h4" toggle={onCloseClick}>
        Filters
      </ModalHeader>
      <ModalBody className="py-3 px-0">
        {/* Select input for filtering by regions */}
        <Row className="px-3">
          <Col xs={12}>
            <Label className="fw-bolder mt-2"> Date</Label>
            <Input
              placeholder="Date"
              value={SelectedDate}
              type="date"
              min={SelectedDate}
              // onBlurCapture={() => handleDateChange()}
              onChange={(e) => setSelectedDate(e.target.value)}
            />{" "}
          </Col>
        </Row>
        <Row className="px-3">
          <Col xs={12}>
            <Label className="fw-bolder mt-2">Start Date</Label>
            <Input
              placeholder="Start Date"
              value={date_from}
              type="date"
              min={date_from}
              onBlurCapture={() => handleDateChange()}
              onChange={(e) => setFromDate(e.target.value)}
            />{" "}
            <Label className="fw-bolder mt-2">End Date</Label>{" "}
            <Input
              placeholder="End Date"
              value={date_to}
              onBlurCapture={() => handleDateChange()}
              type="date"
              min={date_to}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Col>
        </Row>

        <Row className="px-3">
          <Col xs={4}>
            <Label className="fw-bolder mt-2">Filter by Initiated By:</Label>
          </Col>
          <Col xs={12}>
            <Select
              name="countries"
              onChange={(e) => setSelectedCountries(e)}
              value={selectedCountries}
              options={countries?.map((dep) => ({
                value: dep.guid,
                label: dep.full_name,
              }))}
              // isMulti
            />
          </Col>
        </Row>
        <Row className="px-3">
        <Col xs={12}>
            <Label className="fw-bolder mt-2">Filter by Status</Label>
            {/* <Input
              placeholder="status"
              value={selectedStatus}
              type="text"
              // min={date_from}
              // onBlurCapture={() => handleDateChange()}
              onChange={(e) => setSelectedStatus(e.target.value)}
            />{" "} */}
             <Select
              name="countries"
              onChange={(e) => setSelectedStatus(e)}
              value={selectedStatus}
              options={[
                {
                  value: "Approved",
                  label: "Approved",
                },
                {
                  value: "Pending",
                  label: "Pending",
                },
                {
                  value: "Rejected",
                  label: "Rejected",
                },
              ]}
              // isMulti
            />
          </Col>
          </Row>
          <Row className="px-3">
        <Col xs={12}>
            <Label className="fw-bolder mt-2">Filter by Action</Label>
            {/* <Input
              placeholder="status"
              value={selectedStatus}
              type="text"
              // min={date_from}
              // onBlurCapture={() => handleDateChange()}
              onChange={(e) => setSelectedStatus(e.target.value)}
            />{" "} */}
             <Select
              name="countries"
              onChange={(e) => setSelectedAction(e)}
              value={selectedAction}
              options={[
                {
                  value: "CREATION",
                  label: "CREATION",
                },
                {
                  value: "UPDATION",
                  label: "UPDATION",
                },
                {
                  value: "DELETION",
                  label: "DELETION",
                },
              ]}
              // isMulti
            />
          </Col>
          </Row>
        {/* "Apply Filter" button */}
        <Row>
          <Col>
            <div className="text-end m-2">
              <button
                type="submit"
                className="btn btn-primary save-user"
                onClick={onApply}
              >
                Apply Filter
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

// Prop types validation for the component's props
FilterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  tag: PropTypes.string,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  filterValues: PropTypes.object,
  handleFilter: PropTypes.func.isRequired,
  setPreviousFilterValue: PropTypes.func.isRequired,
  previousFilterValues: PropTypes.object,
};

export default FilterModal;
