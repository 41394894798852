import React from 'react'
import Loader from '../Assets/images/table.png'
function TableLoader() {
  return (
    <div className="load">
    <img
      src={Loader}   
         alt="loading"
    />{" "}
  
  </div>
  )
}

export default TableLoader