import React, { useState } from "react";
import { Row, Col, Input, UncontrolledTooltip, Label } from "reactstrap";
import { TableButton } from "./StyledComponents";
import useDebounce from "./customHooks/useDebounce";
import useDidMountEffect from "./customHooks/useEffectNotInitial";

const   TableActionHeader = ({
  onEditColumn = () => {},
  isLimit = true,
  limit = 10,
  onSetLimit = () => {},
  handleSearch = () => {},
  isSearch=true,
  isFilter = false,
  isEditColumn = true,
  handleFilter = () => {},
  previousFilterValues = {},
  handleClearFilter = () => {},
  textInSearch
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useDidMountEffect(
    () => {
      if(isSearch){
        handleSearch(debouncedSearchTerm);
      }  
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  return (
    <>
      {isFilter && (
        <UncontrolledTooltip placement="bottom" target="applyFilterTooltip">
          Apply Filter
        </UncontrolledTooltip>
      )}
      {Object.values(previousFilterValues).filter(
        (el) => el !== null && el !== undefined && el !== "" && el.length !== 0
      ).length > 0 && (
        <UncontrolledTooltip placement="bottom" target="clearFilterTooltip">
          Clear Filter
        </UncontrolledTooltip>
      )}
      {isEditColumn && (
        <UncontrolledTooltip placement="bottom" target="editColumnTooltip">
          Edit Column
        </UncontrolledTooltip>
      )}

      <Row className="mb-3 g-2">
        <Col sm="5" xs="12">

          {isSearch ? <div className="d-inline-flex w-100">
            <Label className="form-label m-auto">Search:</Label>
            <Input
              type="search"
              className="w-100 ms-2"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>  : <h4 className="text-primary  mt-2">{textInSearch}</h4>}
          
        </Col>

        <Col sm="4" xs="6">
          <div className="float-end d-flex-inline">
            {Object.values(previousFilterValues).filter(
              (el) =>
                el !== null && el !== undefined && el !== "" && el.length !== 0
            ).length > 0 && (
              <TableButton
                id="clearFilterTooltip"
                // className="btn mb-2 m"
                onClick={handleClearFilter}
              >
                <i className="mdi mdi-filter-off" />
              </TableButton>
            )}
            {isFilter && (
              <TableButton
                id="applyFilterTooltip"
                // style = {{marginRight:"30px"}}
                // className="btn mb-2 m"
                onClick={handleFilter}
              >
                <i className="mdi mdi-filter " />
              </TableButton>
            )}
            {isEditColumn && (
              <TableButton
                // type="button"
                // color="primary"
                id="editColumnTooltip"
                // className="btn mb-2 m"
                onClick={() => onEditColumn()}
              >
                <i className="mdi mdi-format-columns " />
              </TableButton>
            )}
          </div>
        </Col>
        {isLimit === true ? (
          <Col  sm="3" xs="4" >
            <Input
              type="select"
               className="form-select"
           
              value={limit}
              onChange={(e) => onSetLimit(e.target.value)}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize} >
                  Show {pageSize} Entries
                </option>
              ))}
            </Input>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default React.memo(TableActionHeader);
