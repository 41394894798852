import PropTypes from "prop-types";
import React ,{useState} from "react";
import {
  Row,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
// import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../Assets/images/logo.png";
import CarouselPage from "./CarouselPage";
import { postForgetPassword } from "../../components/auth";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  //meta title
  document.title = "Forget Password | Lucky Cement";
  const navigate=useNavigate()
  const [preLoader,setPreloader]=useState(false)
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const preLoader = useSelector((state) => state.Layout.isPreloader);
const handleForgePassword=async(value)=>{
  const response =await postForgetPassword( { email: value.email.toLowerCase()}).then((res)=>{
    if(res){
      navigate('/reset-password')
      toast.success("OTP Sent Successfully");
    }
   
  }).finally(()=>{
    
    setPreloader(false)
  })
}
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      // dispatch(userForgetPassword(values, history));

      setPreloader(true)
      handleForgePassword(values)
    },
  });

  return (
    <React.Fragment>
  
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logo} alt="" height="60" />
                      
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                        
                      </div>

                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row className="mb-3">
                          <Col className="text-end">
                            {preLoader ? (
                              <button
                                disabled={preLoader}
                                className="btn btn-primary btn-block  w-100"
                                type="submit"
                              >
                                <span
                                  className="spinner-border spinner-border-sm "
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="m-2"> Loading... </span>
                              </button>
                            ) : (
                              <button
                                disabled={preLoader}
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          
                          <Link to="/login" className="text-primary fw-semibold">
                            
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lucky Cement Limited.
                        Developed by Joyn Digital
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
       
     
    </React.Fragment>
  );
};

// ForgetPassword.propTypes = {
//   history: PropTypes.object,
// };

export default ForgetPassword;
