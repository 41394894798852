import { useFormik } from "formik";
import React, { useEffect, useState, useRef, useMemo } from "react";
// import { useSelector, useDispatch } from "react-redux";

import { Col, Row, Form, Label, Input } from "reactstrap";
import {
  AsyncSelectCommon,
  SelectCommon,
} from "../../../../Common/FormCommonFormik";
import { loadOneTimeOptions } from "../../../../Common/optionRequest";
import * as Yup from "yup";
// import { getDepartments } from "../../../store/actions";
import { ApiCall } from "../../../../Common/CommonCallApi";
function EmployFormBulk({ setformValue, SaveButton }) {
  const [isDesignationLoading, setDesignationIsLoading] = useState(false);
  const [designationsOptions, setDesignationsOptions] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [employee, setEmployee] = useState();
  // const dispatch = useDispatch ()
  useEffect(() => {
    ApiCall("get", "department", { is_active: "ACTIVE" }, setDepartment);
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      allow_login: (employee && employee.allow_login) || false,
      department:
        (employee && {
          value: employee.department?.id || "",
          label: employee.department?.name || "",
        }) ||
        "",
      designation:
        (employee && {
          value: employee.designation?.id || "",
          label: employee.designation?.name || "",
        }) ||
        "",
      grade: (employee && employee?.grade) || "",
      report_to: (employee && employee.report_to) || "",

      is_active: (employee && employee.is_active) || "",
    },

    validationSchema: Yup.object({
      // email: Yup.string().required("Please Enter Email"),
      department: Yup.object().shape({
        value: Yup.string().required("Please Select Your Department"),
      }),
      designation: Yup.object().shape({
        value: Yup.string().required("Please Select Designation"),
      }),

      grade: Yup.object().shape({
        value: Yup.string("Please Select Grade"),
      }),
      // role: Yup.object().shape({
      //   value: Yup.string("Please Select Role"),
      // }),
      allow_login: Yup.boolean(),

      // role: Yup.object().shape({
      //   value: Yup.string().matches(
      //     /^[a-zA-Z0-9]+$/,
      //     "Invalid alphanumeric format"
      //   ),
      // }),

      // designation: Yup.string().required("Please Enter Designation"),
    }),
    onSubmit: (values) => {
      const BulkUpdateForm = {
        designation: values["designation"]?.value,
        department: values["department"]?.value,
        grade: values["grade"]?.value,
        reports_to: values["report_to"]?.value,
        is_active: values.is_active,
      };
      // save new Employee
      console.log(
        BulkUpdateForm,
        "BulkUpdateForm BulkUpdateForm BulkUpdateForm",
        "emply0",
        employee
      );

      SaveButton(BulkUpdateForm);
    },
  });

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              validation={validation}
              isRequired
              name="department"
              label={"Department"}
              // uri="department"
              options={departments.map((department) => ({
                value: department.id,
                label: department.name,
              }))}
              loadDependentData={(e) => {
                loadOneTimeOptions(
                  `designation?department=${e}`,
                  setDesignationIsLoading,
                  setDesignationsOptions
                );
                // validation.setFieldValue("designation", "");
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              validation={validation}
              isRequired
              name="designation"
              label={"Designation"}
              // isDisabled={
              //   validation.values.department.value === "" ? true : false
              // }
              isLoading={isDesignationLoading}
              // uri="department"
              options={designationsOptions}
              dependency="department "
              dependencyValue={validation?.values?.department?.value || ""}
            />
            {/* <AsyncSelectCommon
            validation={validation}
            name="designation"
            label={"Designation"}
            uri={"designation?department=" + validation.values.department.value}
          /> */}
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <div className="mb-3">
              <Label className="form-label">
                Grade
                {/* {isRequired ? <span className="text-danger fw-bolder">*</span> : null} */}
              </Label>
              <Input
                placeholder="Grade as per designation"
                value={validation?.values?.designation?.value || ""}
                disabled
              />
              {/* <AsyncSelectCommon
            validation={validation}
            name="grade"
            label={"Grade"}
            uri="designation"
          /> */}
            </div>
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <AsyncSelectCommon
              validation={validation}
              name="report_to"
              label={"Report to"}
              uri="employee"
              // loadOptions={loadOptions}
            />
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <AsyncSelectCommon
              validation={validation}
              name="role"
              label="Role"
              uri="role"
              isRequired
            />
          </Col>

          <div>
            <h6>
              Status<span style={{ color: "#dc3545;" }}>*</span>
            </h6>
            {/* <div className="d-flex">
              <input
                type="radio"
                id="age1"
                defaultChecked
                name="is_active"
                onChange={(e) => validation.setFieldValue("is_active", "")}
                value="ACTIVE"
                className="me-1"
              />
              <label htmlFor="age1">Default</label>
            </div> */}
            <div className="d-flex">
              <input
                type="radio"
                id="age1"
                name="is_active"
                onChange={(e) =>
                  validation.setFieldValue("is_active", "ACTIVE")
                }
                value="ACTIVE"
                className="me-1"
              />
              <label htmlFor="age1">Active</label>
            </div>
            <div className="d-flex">
              <input
                type="radio"
                id="age2"
                name="is_active"
                value="INACTIVE"
                onChange={(e) =>
                  validation.setFieldValue("is_active", "INACTIVE")
                }
                className="me-1"
              />
              <label htmlFor="age2">InActive</label>
            </div>
          </div>
        </Row>

        <button
          type="submit"
          style={{ float: "right", marginTop: "1rem", width: "95px" }}
          className="btn btn-primary save-user"
        >
          Save
        </button>
      </Form>
    </div>
  );
}

export default EmployFormBulk;
