import { toast } from "react-toastify";
import {
  addApiCall,
  deleteApiCall,
  getApiCall,
  updateApiCall,
} from "./ApiCall";
import Swal from "../../node_modules/sweetalert2/dist/sweetalert2.all.min";
/////////////////// type as string to define , if its get or update api , next we neeed URL of api , 3 is params need to pass here ,
/// 4 th is state to update if we get any result  , last one is setLoading is loading state,
export const ApiCall = async (
  type,
  url,
  params,
  set,
  setloading,
  setCount,
  setModal,
  modal,
  setLoadingModal,
  toogle,
  showMessege = false,
  postParams = undefined
) => {
  switch (type) {
    
    case "get":
      const setloadingExist = typeof setloading === "function";
      if (setloadingExist) {
        setloading(true);
      }

      const hasOnClickProp = typeof setCount === "function";
      try {
        let bas = await getApiCall(params, url)
          .then((res) => {
            set(res?.data);
            if (hasOnClickProp) {
              setCount(res?.count);
              return res;
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.data?.error || "Something went wrong");
          })
          .finally(() => {
            if (setloadingExist) {
              setloading(false);
            }
            if (typeof setModal === "function") {
              setModal(!modal);
            }
          });

        return bas;
      } catch (error) {
        toast.error(error?.response?.data?.data?.error || "Something went wrong ss");
      }
      break;
    case "update":
      try {
        const setloadingExist = typeof setloading === "function";

        if (setloadingExist) {
          setloading(true);
        }

        const a = await updateApiCall(params, url)
          .then((res) => {
            toast("Record Updated Successfully");
           
            return res;
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message || "Something went wrong");
          });

        return a;
      } catch (error) {
        toast.error(error?.response.data.message || "Something went wrong");
      }
      break;
    case "delete":
      try {
        const a = await deleteApiCall(params, url)
          .then((res) => {
            if (res !== undefined) {
              Swal.fire("Deleted!", "Record Deleted.", "success");
              return res;
            }
          })
          .catch((err) => {
            toast.error(err?.response.data.message || "Something went wrong");
          });
        return a;
      } catch (error) {
        toast.error(error?.response.data.message || "Something went wrong");
      }

      break;
    case "add":
      try {
        const setloadingExist = typeof setloading === "function";

        if (setloadingExist) {
          setloading(true);
        }
      //   const res = await addApiCall(params, url, postParams);
      //   // !hideToast && toast.success("Record Added Successfully");
      //   if (!showMessege) {
      //     toast.success("Record Added Successfully");
      //   }

      //   if (typeof setModal === "function") {
      //     setModal(!modal);
      //   }

      //   if (typeof toogle === "function") {
      //     toogle();
      //   }
      //   return res;
      // } catch (error) {
      //   let err = error?.response.data.message;
      //   let errorCapitalize = err?.charAt(0)?.toUpperCase() + err.slice(1);
      //   toast.error(errorCapitalize || "Something went wrong");
      // }
      const a = await addApiCall(params, url,postParams)
      .then((res) => {
        toast.success("Record Added Successfully");
        if (typeof setloading === "function") {
          setloading(false);
        }
        return res;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        setloading(false);

      });

    return a;
  } catch (error) {
        let err = error?.response.data.message;
        let errorCapitalize = err?.charAt(0)?.toUpperCase() + err.slice(1);
        toast.error(errorCapitalize || "Something went wrong");
        setloading(false);

  }

      break;

    default:
      break;
  }
};
