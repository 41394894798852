import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInformation: null,
};

const userInformationSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserInformationSliceArray: (state, action) => {
      state.userInformation = action.payload;
    },
    clearUserInformationSliceArray: (state) => {
      state.userInformation = null;
    },
  },
});

export const { setUserInformationSliceArray, clearUserInformationSliceArray } =
  userInformationSlice.actions;

export default userInformationSlice.reducer;
