import React, { useState ,useEffect } from "react";
import Logo_sm from "../../Assets/images/logo-sm.svg";
import Logo_full from "../../Assets/images/logofull.svg";
import userAvatar1 from "../../Assets/images/users/avatar-1.jpg";
import { Link, useNavigate } from "react-router-dom";
import secondary from "../../Assets/images/icons/SecondarySales.svg";
import { ApiCall } from "../../Common/CommonCallApi";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformationSliceArray } from "../../store/Slices/userInformationSlice";
import { getApiCall } from "../../Common/ApiCall";
// import useClickOutside from "../../Common/customHooks/useClickOutsideAndHideDiv";

function NavbarHeader({
  sidebarToogle,
  handleSidebarToogle,
  homepage = false,
}) {
  const [userDropDown, setuserDropDown] = useState(false);
  const [userRoutesDropDown, setuserRoutesDropDown] = useState(false);
  const [modalShown, toggleModal] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const handleDropdown = () => {
    setuserDropDown(!userDropDown);
    setuserRoutesDropDown(false);
  };
  // const UserRouteDropDownRef=useClickOutside(()=>{
  //   // setuserRoutesDropDown(false);
  // })
  // const userDropDownref=useClickOutside(()=>{
  //   setuserDropDown(false);
  // })
  const dispatch=useDispatch()
  const [user, setUser] = useState(null);
  const [loading, setloading] = useState(true);
  const [count, setCount] = useState(0);
const userInformation = useSelector((state)=>state.userInformationSlice)

useEffect(()=>{
 
  if(userInformation.userInformation ==null ){
    
    getApiCall({self:true},'user').then((res)=>{
      console.log(res)
      dispatch(setUserInformationSliceArray(res?.data[0]));
     })
    
  }
},[userInformation])
  // const ComponentAPI = "/user";

  // useEffect(() => {
  //   ApiCall("get", ComponentAPI, { self: true }, setUser, setloading, setCount);
    
  // }, []);

  const handleRoutesDropdown = (e) => {
    console.log(e.close);
    setuserRoutesDropDown(!userRoutesDropDown);
    setuserDropDown(false);
  };
  // const ref = useRef(null);
  // const { onClickOutside } = props;

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onClickOutside && onClickOutside();
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [ onClickOutside ]);

  // if(!props.show)
  //   return null;

  return (
    <header id="page-topbar"   >
      <div className="navbar-header">
        <div className={homepage ? "d-flex border-bottomHome" : "d-flex"}>
          <div className="navbar-brand-box">
            <Link className="logo" to="/home">
              {!sidebarToogle ? (
                <span className="logo-lg">
                  <img src={Logo_full} alt="" height="40" />
                </span>
              ) : (
                <span className="logo">
                  <img src={Logo_sm} alt="" height="24" />
                </span>
              )}
            </Link>
          </div>

          {!homepage ? (
            <button
              onClick={() => handleSidebarToogle()}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <span className="mdi mdi-menu"></span>
            </button>
          ) : null}
        </div>

        <div className="d-flex">
          <div className="d-inline-block dropdown"  onPointerLeave={(e)=>{setuserRoutesDropDown(false)}}>
            <button
           
              onClick={(e) => handleRoutesDropdown(e)}
              id="page-header-user-dropdown"
              aria-haspopup="true"
              className="btn header-item bg-soft-light border-start border-end"
              aria-expanded="false"
            >
              <span className="d-none d-xl-inline-block ms-2 me-1">
                <span className="mdi mdi-folder-account-outline navbarSelectionOfMainRoutesIconCustom"></span>
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>

            <div
            //  ref={UserRouteDropDownRef}
              tabIndex="-1"
              role="menu"
              aria-hidden="true"
              style={{
                position: "absolute",
                // inset: " 0px auto auto 0px",
                transform: "translate3d(0px, 60px, 0px)",
              }}
              className={
                userRoutesDropDown
                  ? "dropdown-menu-end dropdown-menu show"
                  : "dropdown-menu-end dropdown-menu"
              }
            >
              <Link
                to="../systemAdmin/dashboard"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item "
              >
                <i className="mdi mdi-account-outline font-size-16 align-middle me-1"></i>
                System Admin{" "}
              </Link>

              <div className="dropdown-divider"></div>

              <Link
                to="../secondarySales/dashboard"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item "
              >
                <img
                  alt=""
                  style={{ width: "15px", height: "15px", filter: 'brightness(0.000000001)'}}
                  src={secondary}
                />{" "}
                Secondary Sales{" "}
              </Link>

              <div className="dropdown-divider"></div>

              <Link
                to="../institutionalSales/dashboard"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item "
              >
                <i className="mdi mdi-account-outline font-size-16 align-middle me-1"></i>
                Institutional Sales{" "}
              </Link>
            </div>
          </div>

          <div className="dropdown-divider"></div>

          <div className="d-inline-block dropdown"  onPointerLeave={(e)=>{setuserDropDown(false)}}>
            <button
              onClick={() => handleDropdown()}
              id="page-header-user-dropdown"
                            //  ref={userDropDownref}

              aria-haspopup="true"
              className="btn header-item bg-soft-light border-start border-end d-flex align-items-center"
              aria-expanded="false"
            >


{userInformation.userInformation !=null ? userInformation.userInformation?.image ==null  ? 
 <div className="bg-primary headerProfileText">{(userInformation.userInformation?.first_name !=null && userInformation.userInformation?.last_name !=null)? `${userInformation.userInformation?.first_name.slice(0, 1).toUpperCase()}${userInformation.userInformation?.last_name.slice(0, 1).toUpperCase()}` : 'NN'}</div>  : <img
                      name="image"
                      className="rounded-circle header-profile-user"

                      src={userInformation?.userInformation.image}
                      
                      alt="Header Avatar"
                    /> :null
                  }


              {/* <span className="d-none d-xl-inline-block ms-2 me-1">User</span> */}
              <i style={{alignSelf:'center'}} className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div
              tabIndex="-1"
              role="menu"
              aria-hidden="true"
              style={{
                position: "absolute",
                // inset: " 0px auto auto 0px",
                transform: "translate3d(0px, 60px, 0px)",
              }}
              className={
                userDropDown
                  ? "dropdown-menu-end dropdown-menu show"
                  : "dropdown-menu-end dropdown-menu"
              }
            >
              <Link
                to="../ProtectedButCommon/profile"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item "
              >
                <i className="mdi mdi-account-outline font-size-16 align-middle me-1"></i>
                Profile{" "}
              </Link>
              <div className="dropdown-divider"></div>
              <div
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleLogout(e)}
              >
                <i className="mdi mdi-power font-size-16 align-middle me-1 text-danger"></i>
                <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavbarHeader;
