
import React ,{useState} from "react";
import {
  Row,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import YupPassword from "yup-password";
import { useFormik } from "formik";

// action
// import { userResetPassword } from "../../store/actions";

// import images
import logo from "../../Assets/images/logofull.svg";
import CarouselPage from "./CarouselPage";
import { postResetPassword } from "../../components/auth";
import { toast } from "react-toastify";

YupPassword(Yup);
const ForgetPasswordPage = () => {
  //meta title
  // const history = useHistory();
  document.title = "Forget Password | Lucky Cement";
  const navigate= useNavigate()
  const [preLoader,setPreloader]=useState(false)
  // const dispatch = useDispatch();
  // const preLoader = useSelector((state) => state.Layout.isPreloader);
const handleuserResetPassword=async(values)=>{
 const res=await postResetPassword({
  otp: values.otp,
  new_password: values.new_password,
  confirm_password: values.confirm_password,
}).then((res)=>{
  toast.success("Password changed successfully, please login again");
navigate('/login')
}).catch((err)=>{
  
  toast.error(err?.response?.data?.message)
})
}
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      otp: Yup.number()
        .required("Please enter correct OTP code")
        .min(6, "OTP must be at least 6 digit"),
      // .max(6, "OTP must be 6 digit"),
      new_password: Yup.string()
        .required("Please enter new Password")
        .min(
          8,
          "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
        )
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character"),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values) => {
      // dispatch(userResetPassword(values, history));
      handleuserResetPassword(values)
    },
  });

  return (
    <React.Fragment>
       <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="#" className="d-block auth-logo">
                        <img src={logo} alt="" height="58" />{" "}
                        <span className="logo-txt">Lucky</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                    
                      </div>

                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">OTP Code</Label>
                          <Input
                            name="otp"
                            className="form-control"
                            placeholder="Enter otp code"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.otp || ""}
                            invalid={
                              validation.touched.otp && validation.errors.otp
                                ? true
                                : false
                            }
                          />
                          {validation.touched.otp && validation.errors.otp ? (
                            <FormFeedback type="invalid">
                              {validation.errors.otp}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="new_password"
                            value={validation.values.new_password || ""}
                            type="password"
                            placeholder="Enter new password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.new_password &&
                              validation.errors.new_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.new_password &&
                          validation.errors.new_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.new_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="confirm_password"
                            value={validation.values.confirm_password || ""}
                            type="password"
                            placeholder="Enter confirm password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.confirm_password &&
                              validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            {preLoader ? (
                              <button
                                disabled={preLoader}
                                className="btn btn-primary btn-block  w-100 "
                                type="submit"
                              >
                                <span
                                  className="spinner-border spinner-border-sm "
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="m-2"> Loading... </span>
                              </button>
                            ) : (
                              <button
                                disabled={preLoader}
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Change Password
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Remember It ?{" "}
                          <Link to="/login" className="text-primary fw-semibold">
                            {" "}
                            Sign In{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lucky Cement.
                      </p>
                      <p style={{fontSize: '12px',
    fontWeight: '600',
    fontFamily: 'system-ui'}}>Developed by
                        Joyn Digital</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
         
    </React.Fragment>
  );
};


export default ForgetPasswordPage;
