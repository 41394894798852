import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  BreadcrumbItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

const Breadcrumb = ({
  filters,
  breadcrumbItem,
  onFilter,
  single = false,
  title,
  goto,
  isPopUp = false,
}) => {
  return (
    <Row className={isPopUp && "w-100 pt-2"}>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {/* <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
           */}
          {filters ? (
            <UncontrolledDropdown>
              <DropdownToggle nav caret>
                <span className="font-size-18 fw-normal text-primary">
                  {" "}
                  {breadcrumbItem} <i className="mdi mdi-chevron-down" />
                </span>
              </DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>Header</DropdownItem> */}
                {/* <DropdownItem disabled>Action</DropdownItem> */}
                {filters.map((filter, index) => (
                  <React.Fragment key={index}>
                    <DropdownItem onClick={() => onFilter(filter)}>
                      {filter}
                    </DropdownItem>
                  </React.Fragment>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <h4 style={{ color: "#8B1C1C" }} className="mb-0 font-size-20">
              {breadcrumbItem}
            </h4>
          )}
          {single == true ? null : (
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to={goto || "#"}>{title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{breadcrumbItem}</Link>
                </BreadcrumbItem>
              </ol>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
