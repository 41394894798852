import React, { useEffect, useState } from "react";

import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedLayout from "./Layout/Protected";
import PublicLayout from "./Layout/Public/PublicLayout";
import {
  ISPRoutes,
  ProtectedButCommon,
  SSPRoutes,
  authRoutes,
  userRoute,
} from "./routes";

import LandingPage from "./Pages/LandingPage";
import { ToastContainer, toast } from "react-toastify";
import NotFound from "./Common/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "./Common/CommonCallApi";
import { setFeaturesArray } from "./store/Slices/featureSlice";
import { getApiCall } from "./Common/ApiCall";

function App() {
  if (window !== window.top) {
    window.location.href = "https://www.google.com/"; // Replace with the URL of your error page
  }
  const dispatch = useDispatch();
  const [dataFeatureArray, setDataFeatureArray] = useState(null);

  const { featuresArray } = useSelector((state) => state.featureSlice);

  useEffect(() => {
    const auth = window.localStorage.getItem("token");
    if (auth) {
      if (featuresArray.length == 0 || featuresArray == undefined) {
        getApiCall({}, "user?self=True").then((res) => {
          if (res) {
            dispatch(setFeaturesArray(res.data[0]?.role));
          }
        });
      }
    }
  }, []);

  return (
    <>
      <HashRouter>
        <ToastContainer
          data-testid="app-component"
          position="top-right"
          autoClose={5000}
          draggable
        />
        <Routes>
          <Route path="/" Component={PublicLayout}>
            {authRoutes.map((index) => (
              <Route path={index.path} element={index.component} />
            ))}
          </Route>

          <Route path="/ProtectedButCommon" Component={ProtectedLayout}>
            {ProtectedButCommon.map((index) => (
              <Route path={index.path} element={index.component} />
            ))}
          </Route>
          <Route path="/systemAdmin" Component={ProtectedLayout}>
            {userRoute.map((index) => (
              <Route path={index.path} element={index.component} />
            ))}
          </Route>
          <Route path="/institutionalSales" Component={ProtectedLayout}>
            {ISPRoutes.map((index) => (
              <Route path={index.path} element={index.component} />
            ))}
          </Route>
          <Route path="/secondarySales" Component={ProtectedLayout}>
            {SSPRoutes.map((index) => (
              <Route path={index.path} element={index.component} />
            ))}
          </Route>
          <Route path="/home" element={<LandingPage />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
