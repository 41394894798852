import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "reactstrap";
import { SelectCommon } from "../../../../Common/FormsCommon";
import { ApiCall } from "../../../../Common/CommonCallApi";

function BrandBulk({ SaveButton }) {
  const [brandsType, setbrandsType] = useState([]);
  const initialValue = {
    brand_type: {
      value: "",
      label: "",
    },
    is_active: "",
  };

  const [form, setForm] = useState(initialValue);
  console.log(brandsType, "brandsType brandsType ");
  const [brand, setBrand] = useState();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      brand_type:
        (brand && {
          value: brand?.brand_type?.id || "",
          label: brand?.brand_type?.name || "",
        }) ||
        "",
      is_active: (brand && brand?.is_active) || "",
    },
    onSubmit: (values) => {
      const newBrand = {
        brand_type: values.brand_type.value,
        is_active: values?.is_active,
      };
      // save new user
      // dispatch(onAddNewBrands(newBrand, offset, limit, validation, onSetOff));
      SaveButton(newBrand);
    },
  });

  // const handleChange = (e,sel,sea ,asd,asds) => {
  //   debugger
  //   setForm((prev)=>({...prev,brand_type:e.target.value}))
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newBrand = {
      brand_type: form.brand_type.value,
      is_active: form?.is_active,
    };
    debugger;
    SaveButton(newBrand);
  };
  const handleRadioButtons = (e) => {
    validation.setFieldValue("isActive", e.target.value);
    debugger;
  };

  const handleInputChange = (event, names, sel, dependencyArray) => {
    if (names) {
      if (sel == undefined) {
        setForm((prev) => ({
          ...prev,
          [names]: "",
        }));
        return;
      } else {
        setForm((prev) => ({
          ...prev,
          [names]: sel,
        }));
        return;
      }
    }
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    ApiCall(
      "get",
      "/market_intelligence/brand_type",
      { is_active: "ACTIVE" },
      setbrandsType
    );
  }, []);
  // const handleRadioButtons = (e) => {
  //   validation.setFieldValue("isActive", e.target.value);
  // };

  const handleChangeForm = (e) => {
    setForm((prev) => ({
      ...prev,
      is_active: e.target.value,
    }));
  };
  return (
    <div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <SelectCommon
          onChange={handleInputChange}
          name="brand_type"
          value={form.brand_type}
          label={"Brand Type"}
          options={brandsType.map((region) => ({
            value: region.id,
            label: region.name,
          }))}
        />

        <div>
          <h6>
            Status <span style={{ color: "#dc3545;" }}>*</span>{" "}
          </h6>
          {/* <div className="d-flex">
            <input
              type="radio"
              id="age1"
              defaultChecked
              name="is_active"
              onChange={handleInputChange}
              // onChange={(e) => validation.setFieldValue("is_active", "")}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Default</label>
          </div> */}

          <div className="d-flex">
            <input
              type="radio"
              id="age1"
              name="is_active"
              onChange={handleInputChange}
              //  onChange={(e) => validation.setFieldValue("is_active", "ACTIVE")}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Active</label>
          </div>

          <div className="d-flex">
            <input
              type="radio"
              id="age2"
              name="is_active"
              value="INACTIVE"
              onChange={handleInputChange}
              // onChange={(e) => validation.setFieldValue("is_active", "INACTIVE")}
              className="me-1"
            />
            <label htmlFor="age2">InActive</label>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary save-user"
          style={{ float: "right", marginTop: "1rem", width: "95px" }}
        >
          Save
        </button>
      </Form>
    </div>
  );
}

export default BrandBulk;
