export function clearFormFields(fieldsToClear, formData, setFormData) {
  if (formData?.lau != undefined || null) {
    const updatedFormData = { ...formData.lau };

    fieldsToClear.forEach((field) => {
      updatedFormData[field] = "";
    });

    setFormData(updatedFormData);
  } else {
    const updatedFormData = { ...formData.form };

    fieldsToClear.forEach((field) => {
      updatedFormData[field] = "";
    });

    setFormData(updatedFormData);
  }
}
