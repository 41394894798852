import React from "react";
import { useState, useMemo } from "react";
import {
  CardBody,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Row,
  Col,
  Card,
  Button,
} from "reactstrap";
// import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import Step1 from "./Step1";
import { getBulk } from "../../../Common/BulkUpdateApi";
import { MemoizedStep2 } from "./Step2";
import { MemoizedStep3 } from "./Step3";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
const BulkUpdateStepper = () => {
  const [value, setValue] = useState(1);
  const [checked, setChecked] = useState(false);
  const [loading, isLoading] = useState(false);
  const [TablecheckedList, setTableCheckedList] = useState([]);
  const [columnArrays, setColumnArrays] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [apiData, setapiData] = useState([]);
  function handleChange() {
    if (checked === false) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }
  const ColumnsDetect = (value) => {
    const obj = value ? value?.data[0] : [];
    const ab = Object.keys(obj).map(function (key) {
      return { key: key, type: typeof obj[key] };
    });
    let objs = ab.filter(
      (index) =>
        index.key != "is_deleted" &&
        index.key != "created_at" &&
        index.key != "updated_at" &&
        index.key != "deleted_at" &&
        index.key != "id"
    );
    setColumnArrays(objs);
    console.log(objs, "ashdkjashkh ");
  };

  // const prevButton = () => {
  //   if (value !== 1) {
  //     setValue(value - 1);
  //   }
  // };

  const handleApi = async () => {
    const data = await getBulk({ api: selectedModule, offset: "all" });
    setapiData(data);
    ColumnsDetect(data);
    setValue(value + 1);
    isLoading(false);
  };
  const handleSecondPage = () => {
    if (TablecheckedList.length <= 0) {
      toast.error("Please Select At Least one record");
    } else {
      setValue(value + 1);
    }
  };
  const nextButton = () => {
    switch (value) {
      case 1:
        if (selectedModule === "") {
          toast.warning("Please Select Atleast One Module to proceede further");
          break;
        } else {
          isLoading(true);
          handleApi();
        }
        break;
      case 2:
        handleSecondPage();
      case 3:
        break;
      default:
        break;
    }
  };

  const PreviousButton = () => {
    switch (value) {
      case 3:
        setValue(value - 1);
        break;
      case 2:
        setTableCheckedList([]);
        setValue(value - 1);

      case 1:
        break;
      default:
        break;
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <SimpleBar
      style={{ maxHeight: "calc(100vh - 10px)", width: "70vw", margin: "auto" }}
    >
      <Card className="border-0">
        <CardBody>
          <div className="d-flex ">
            <h5 className="text-primary">
              {value == 1
                ? "Select Module"
                : value == 2
                ? "Select Data"
                : "Enter Forms "}
            </h5>
            <p className="ms-1 text-primary"> {value}/3</p>
          </div>
          <div id="basic-pills-wizard" className="twitter-bs-wizard">
            <div className="mainStepperInportExport">
              <div className="progress" style={{ height: "5px" }}>
                <div
                 
                  className="progress-bar bg-primary"
                  role="progressbar"
                  style={
                    value == "1"
                      ? { width: "25%" }
                      : value == 2
                      ? { width: "50%" }
                      : { width: "100%" }
                  }
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div>
                <ul
                  style={{ listStyle: "none" }}
                  className="d-flex justify-content-around"
                >
                  <li className="fw-bolder text-primary">
                    <i className="mdi mdi-feature-search-outline"></i> Select
                    Module
                  </li>
                  <li>
                    <i className="mdi mdi-table-check"></i> Select Option
                  </li>
                  <li>
                    <i className="mdi mdi-check"></i> Final
                  </li>
                </ul>
              </div>
            </div>

            <TabContent className="twitter-bs-wizard-tab-content mb-3">
              {/* <TabPane> */}
              <div className="text-center mb-4"></div>
              {/* </TabPane> */}
              {value === 1 && (
                <div>
                  <div className="position-relative">
                    <Step1
                      setSelectedModule={setSelectedModule}
                      selectedModule={selectedModule}
                    />
                  </div>
                </div>
              )}

              {value === 2 && selectedModule === "user" ? (
                <MemoizedStep2
                  setTableCheckedList={setTableCheckedList}
                  user="true"
                  TablecheckedList={TablecheckedList}
                  data={apiData}
                  columnArrays={columnArrays}
                />
              ) : value === 2 ? (
                <MemoizedStep2
                  setTableCheckedList={setTableCheckedList}
                  user="false"
                  TablecheckedList={TablecheckedList}
                  data={apiData}
                  columnArrays={columnArrays}
                />
              ) : null}

              {value === 3 && (
                <MemoizedStep3
                  selectedModule={selectedModule}
                  setTableCheckedList={setTableCheckedList}
                  TablecheckedList={TablecheckedList}
                  setSelectedModule={setSelectedModule}
                  setValue={setValue}
                />
              )}
            </TabContent>
            <div className="pager wizard twitter-bs-wizard-pager-link">
              <div className="d-flex justify-content-between">
                <li className="list-unstyled">
                  <Button
                    style={{ width: "95px" }}
                    disabled={value === 1 ? true : false}
                    className="btn btn-primary"
                    onClick={() => {
                      PreviousButton();
                    }}
                  >
                    <i className="bx bx-chevron-left ms-1"></i>
                    Previous
                  </Button>
                </li>

                <li className="list-unstyled">
                  {value === 2 || value === 1 ? (
                    <>
                      {" "}
                      <Button
                        style={{ width: "95px" }}
                        className="btn btn-primary"
                        onClick={() => {
                          nextButton();
                        }}
                      >
                        Next
                        <i className="bx bx-chevron-right ms-1"></i>
                      </Button>
                    </>
                  ) : null}
                </li>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </SimpleBar>
  );
};

export default BulkUpdateStepper;
