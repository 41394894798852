export const handleCheckedCommon = (item ,checkedList ,setIsSelectAll,setCheckedList) => {
    let temp = [...checkedList];
    if (temp.find((data) => data.id === item?.id)) {
        temp = temp.filter((val) => {
            return val?.id !== item?.id;
        });
    } else {
        temp.push(item);
    }
    setIsSelectAll(false);
    setCheckedList(temp);
};