import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featuresArray: [],
};

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeaturesArray: (state, action) => {
      state.featuresArray = action.payload;
    },
    clearFeaturesArray: (state) => {
      state.featuresArray = [];
    },
  },
});

export const { setFeaturesArray, clearFeaturesArray } = featuresSlice.actions;

export default featuresSlice.reducer;