import React from "react";
import { Button, FormFeedback, Label } from "reactstrap";
import Select from "react-select";
import { useState } from "react";
import { toast } from "react-toastify";
function CombinedForm({ department = "false", SaveButton }) {
  const [form, setForm] = useState({
    is_active: "",
    category: "",
  });
  const handleChangeFormCatagory = (e) => {
    setForm((prev) => ({
      ...prev,
      category: e.value,
    }));
  };
  const handleChangeForm = (e) => {
    setForm((prev) => ({
      ...prev,
      is_active: e.target.value,
    }));
  };
  const handleS = (e) => {
    e.preventDefault();
    SaveButton(form);
  };
  return (
    <>
      <h5 className="text-primary">Operation</h5>
      <p>This operation can not be revert</p>
      <hr />
      <form onSubmit={(e) => handleS(e)}>
        {/* <input
          type="radio"
          id="age1"
          defaultChecked
          name="is_active"
          onChange={(e) => handleChangeForm(e)}
          value=""
          className="me-1"
        />
        <label htmlFor="age1">Default</label>
        <br />
        <input
          type="radio"
          id="age1"
          name="is_active"
          onChange={(e) => handleChangeForm(e)}
          value="ACTIVE"
          className="me-1"
        />
        <label htmlFor="age1">Active</label>
        <br />
        <input
          type="radio"
          id="age2"
          name="is_active"
          value="INACTIVE"
          onChange={(e) => handleChangeForm(e)}
          className="me-1"
        />
        <label htmlFor="age2">Non Active</label> */}

        <div >
          <h6>
            Status<span style={{ color: "#dc3545;" }}>*</span>
          </h6>
          {/* <div className="d-flex">
            <input
              type="radio"
              id="age1"
              defaultChecked
              name="is_active"
              onChange={(e) => handleChangeForm(e)}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Default</label>
          </div> */}
          <div className="d-flex">
            <input
              type="radio"
              id="age1"
              name="is_active"
              onChange={(e) => handleChangeForm(e)}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Active</label>
          </div>

          <div className="d-flex">
            <input
              type="radio"
              id="age2"
              name="is_active"
              value="INACTIVE"
              onChange={(e) => handleChangeForm(e)}
              className="me-1"
            />
            <label htmlFor="age2">InActive</label>
          </div>
          {department == "true" ? (
          <div className="mb-3">
            <Label>Department Category</Label>
            <Select
              id="category"
              name="category"
              placeholder="Select Department Category"
              //   onBlur={() =>
              //     validation.setFieldTouched("category", true)
              //   }
              //   value={validation.values.category}
              //   onChange={(option) =>
              //     validation.setFieldValue("category", option)
              //   }
              //   isSearchable={false}
              onChange={(e) => handleChangeFormCatagory(e)}
              // value={form.catagory}
              options={[
                {
                  value: "IL",
                  label: "Internal Department",
                },
                {
                  value: "EL",
                  label: "External Department",
                },
              ]}
              // formatCreateLabel={(inputText) => `${inputText}`}
            />
            {/* {validation.touched.category &&
                    validation.errors.category?.value ? (
                      <FormFeedback className="d-flex" type="invalid">
                        {validation.errors.category.value}
                      </FormFeedback>
                    ) : null} */}
          </div>
        ) : null}
        </div>
        <br />
      
        <button
          type="submit"
          className="btn btn-primary"
          style={
            department == "true"
              ? { float: "right", marginTop: "1rem", width: "95px" }
              : { float: "right", marginTop: "1rem", width: "95px" }
          }
        >
          Save
        </button>
      </form>
    </>
  );
}

export default CombinedForm;
