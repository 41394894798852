import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";

import { Col, Row, Form } from "reactstrap";
import { SelectCommon } from "../../../../Common/FormCommonFormik";
// import { getRegions } from "../../../store/actions";

import {
  loadOneTimeOptions,
  loadUserOptionsWithoutLogin,
} from "../../../../Common/optionRequest";
import { ApiCall } from "../../../../Common/CommonCallApi";

function OutletBulk({ SaveButton }) {
  // const { regions } = useSelector((state) => state.regionsReducer);
  const [channel, setChannel] = useState();

  const [regions, setRegions] = useState([]);
  const [isCityLoading, setCityIsLoading] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [isZoneLoading, setZoneIsLoading] = useState(false);
  const [zonesOptions, setZonesOptions] = useState([]);
  const [isSubZoneLoading, setSubZoneIsLoading] = useState(false);
  const [subZonesOptions, setSubZonesOptions] = useState([]);

  // const dispatch = useDispatch();
  useEffect(() => {
    if (regions.length <= 0) {
      ApiCall("get", "area/region", { is_active: "ACTIVE" }, setRegions);
    }
  }, []);
  const handleRadioButtons = (e) => {
    validation.setFieldValue("is_active", e.target.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      allow_login: (channel && channel.allow_login) || "",

      category:
        (channel && {
          value: channel?.category || "",
          label: channel?.category === "IL" ? "Internal" : "External" || "",
        }) ||
        "",
      channel:
        (channel && {
          value: channel?.channel?.id || "",
          label: channel?.channel?.name || "",
        }) ||
        "",
      region:
        (channel && {
          value: channel.region?.id || "",
          label: channel.region?.name || "",
        }) ||
        "",
      city:
        (channel && {
          value: channel.city?.id || "",
          label: channel.city?.name || "",
        }) ||
        "",
      zone:
        (channel && {
          value: channel.zone?.id || "",
          label: channel.zone?.name || "",
        }) ||
        "",
      sub_zone:
        (channel && {
          value: channel.sub_zone?.id || "",
          label: channel.sub_zone?.name || "",
        }) ||
        "",
      is_active: (channel && channel.is_active) || "",
    },

    onSubmit: (values) => {
      const newChannel = {
        category: values.category.value,
        sub_zone: values.sub_zone.value,
        allow_login: values.allow_login,
        region: values.region?.value,
        city: values.city?.value,
        zone: values.zone?.value,
        sub_zone: values.sub_zone?.value,
        is_active: values.is_active,
      };

      // save new Channel
      console.log("sa", newChannel);
      SaveButton(newChannel);
    },
  });

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              validation={validation}
              placeholder="Category"
              name="category"
              isRequired
              label={"Category"}
              // uri="department"
              options={[
                { id: "IL", name: "Internal" },
                { id: "EL", name: "External" },
              ].map((category) => ({
                value: category.id,
                label: category.name,
              }))}
            />
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              isRequired="true"
              validation={validation}
              name="region"
              placeholder="Region"
              label={"Region"}
              // uri="department"
              options={regions.map((region) => ({
                value: region.id,
                label: region.name,
              }))}
              dependant="city"
              loadDependentData={(e) => {
                loadOneTimeOptions(
                  `area/city?region=${e}`,
                  setCityIsLoading,
                  setCitiesOptions
                );
                validation.setFieldValue("city", "");
                validation.setFieldValue("zone", "");
                validation.setFieldValue("sub_zone", "");
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              validation={validation}
              placeholder="City"
              isRequired="true"
              name="city"
              label={"City"}
              // isDisabled={
              //   validation.values.department.value === "" ? true : false
              // }
              isLoading={isCityLoading}
              // uri="department"
              options={citiesOptions}
              loadDependentData={(e) => {
                loadOneTimeOptions(
                  `area/zone?city=${e}`,
                  setZoneIsLoading,
                  setZonesOptions
                );
                validation.setFieldValue("zone", "");

                validation.setFieldValue("sub_zone", "");
              }}
              dependency="region"
              dependencyValue={validation.values?.region?.value || ""}
            />
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              validation={validation}
              name="zone"
              placeholder="Zone"
              label={"Zone"}
              isRequired="true"
              // isDisabled={
              //   validation.values.department.value === "" ? true : false
              // }
              isLoading={isZoneLoading}
              // uri="department"
              options={zonesOptions}
              loadDependentData={(e) => {
                loadOneTimeOptions(
                  `area/sub_zone?zone=${e}`,
                  setSubZoneIsLoading,
                  setSubZonesOptions
                );
                validation.setFieldValue("sub_zone", "");
              }}
              dependency="city"
              dependencyValue={validation.values?.city?.value || ""}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={6} xxl={6} sm={12}>
            <SelectCommon
              validation={validation}
              name="sub_zone"
              placeholder="Sub Zone"
              isRequired="true"
              label={"Sub Zone"}
              // isDisabled={
              //   validation.values.department.value === "" ? true : false
              // }
              isLoading={isSubZoneLoading}
              // uri="department"
              options={subZonesOptions}
              dependency="zone"
              dependencyValue={validation.values?.sub_zone?.value || ""}
            />
          </Col>

          <div>
            <h6>
              Status<span style={{ color: "#dc3545" }}>*</span>
            </h6>
            {/* <div className="d-flex">
              <input
                type="radio"
                id="age1"
                defaultChecked
                name="is_active"
                onChange={(e) => validation.setFieldValue("is_active", "")}
                value="ACTIVE"
                className="me-1"
              />
              <label htmlFor="age1">Default</label>
            </div> */}
            <div className="d-flex">
              <input
                type="radio"
                id="age1"
                name="is_active"
                onChange={(e) =>
                  validation.setFieldValue("is_active", "ACTIVE")
                }
                value="ACTIVE"
                className="me-1"
              />
              <label htmlFor="age1">Active</label>
            </div>

            <div className="d-flex">
              <input
                type="radio"
                id="age2"
                name="is_active"
                value="INACTIVE"
                onChange={(e) =>
                  validation.setFieldValue("is_active", "INACTIVE")
                }
                className="me-1"
              />
              <label htmlFor="age2">InActive</label>
            </div>
          </div>
          <h6>Login Status</h6>
          <div className="d-flex">
            <div className="form-check d-flex align-items-baseline">
              <input
                name="allow_login"
                checked={validation.values.allow_login}
                className="form-check-input me-2 align-self-baseline"
                type="checkbox"
                onClick={() =>
                  validation.setFieldValue(
                    "allow_login",
                    !validation.values.allow_login
                  )
                }
                // id="remember-check"
                readOnly
              />
              <label
                className="form-check-label"
                // htmlFor="remember-check"
              >
                Allow Login{" "}
              </label>
            </div>
          </div>
        </Row>
        <button
          type="submit"
          className="btn btn-primary save-user"
          style={{
            float: "right",
            marginTop: "1rem",
            width: "95px",
            marginBottom: "100px",
          }}
        >
          Save
        </button>
      </Form>
    </>
  );
}

export default OutletBulk;
