import axios from "axios";
import axiosRateLimit from "axios-rate-limit";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "./logout";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

class HttpService {
  constructor() {
    const token = window.localStorage.getItem("token");
    const service = axiosRateLimit(
      axios.create({
        baseURL: REACT_APP_BASE_URL,
        headers: token
          ? {
              Authorization: `Bearer ${token}`,

              // Authorization: "Token 4d60727de685ac91c7fd0ae92373c4e11af3c2b4",
            }
          : {},
      }),
      {
        maxRequests: 2,
        perMilliseconds: 1000,
      }
    );

    service.interceptors.response.use(this.handleSuccess, this.handleError);
    // service.interceptors.response.use(this.handleSuccess);

    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    if (error?.response?.status != undefined) {
      switch (error.response.status) {
        case 401:
          window.localStorage.clear();
          toast.error(
            error?.response?.message || "Token expired,  Please login again!"
          );
          window.location.replace("/");
        // case 403:
        //   window.localStorage.clear();
        //   toast({
        //     message:
        //       error?.response?.message || "Invalid Token, Please login again!",
        //   });
        //   window.location.replace("/");

        case 404:
          // Not found
          toast({ message: error?.message || "404 Not Found!" });
        // return <Navigate to="/" />;

        default:
          // Internal server error
          // this.redirectTo("/500");

          toast({
            message:
              error?.response?.data?.message ||
              "Invalid Token, Please login again!",
          });
          break;
      }
    } else {
      toast.error(
        error?.response?.message ||
          "Server Unreachable 🚫 , Check Out Internet Connection and try again later."
      );
      // debugger
      // window.localStorage.clear();
      // window.location.replace("/");
    }
    return Promise.reject(error);
  }

  redirectTo(url) {
    window.location.href = url;
  }

  get(...args) {
    return this.service.get(...args);
  }

  post(...args) {
    return this.service.post(...args);
  }

  put(...args) {
    return this.service.put(...args);
  }

  patch(...args) {
    return this.service.patch(...args);
  }

  delete(...args) {
    return this.service.delete(...args);
  }
}

export default HttpService;
