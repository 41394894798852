import { useEffect, useState } from 'react';

function useIdleTimer(timeToIdle) {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let timeout;

    function resetTimeout() {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsIdle(true), timeToIdle);
    }

    function handleEvent() {
      setIsIdle(false);
      resetTimeout();
    }

    // Listen for events that indicate user activity
    window.addEventListener('mousemove', handleEvent);
    window.addEventListener('keydown', handleEvent);
    window.addEventListener('touchstart', handleEvent);

    // Start the idle timer
    resetTimeout();

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleEvent);
      window.removeEventListener('keydown', handleEvent);
      window.removeEventListener('touchstart', handleEvent);
      clearTimeout(timeout);
    };
  }, [timeToIdle]);

  return isIdle;
}

export default useIdleTimer