import HttpService from "./HttpService";

export const getApiCall = async (param, url) => {
  const axiosApi = new HttpService();
  const { data } =
    param?.offset === "all"
      ? await axiosApi.get(url)
      : await axiosApi.get(url, {
          params: param,
        });
  return data?.data;
};

export const addApiCall = async (channelType, url, params = undefined) => {
  
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post(url, channelType, {
    params: params ? params : {},
  });

  return data?.data;
};
export const deleteApiCall = async (channelType, url) => {
  const axiosApi = new HttpService();
  if (url == "user") {
    const { data } = await axiosApi.delete(
      `${url}?guid=` + [0, ...channelType.delete]
    );

    return data?.data;
  } else {
    const { data } = await axiosApi.delete(
      `${url}?id=` + [0, ...channelType.delete]
    );
    return data?.data;
  }
};
export const updateApiCall = async (channelType, url) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.patch(`${url}`, channelType);
  return data?.data;
};
