import React from "react";
import logo from "../Assets/images/logofull.svg";
const Loader = () => {
  return (
    <>
      <div className="loading-wrapper-global">
        {" "}
        <img className="loading-logo" width={100} src={logo} />
      </div>
    </>
  );
};
export default Loader