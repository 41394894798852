import React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { FormFeedback, Label, Input } from "reactstrap";
import { loadOptions } from "./optionRequest";

export const AsyncSelectCommon = ({
  name,
  label,
  validation,
  uri,
  defaultOptions,
  isMulti = false,
  isRequired = false,
  closeMenuOnSelect = true,
}) => {
  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequired ? (
          <span className="text-danger fw-bolder">*</span>
        ) : null}
      </Label>
      <AsyncSelect
        loadOptions={(searchTerm) => loadOptions(searchTerm, uri)}
        id={name}
        name={name}
        isMulti={isMulti}
        isClearable
        placeholder={"Type to Search "}
        onBlur={() => validation.setFieldTouched(name, true)}
        value={validation.values[name]}
        onChange={(option) => validation.setFieldValue(name, option)}
        formatCreateLabel={(inputText) => `${inputText}`}
        defaultOptions={defaultOptions ? defaultOptions : []}
        noOptionsMessage={(inputText) =>
          inputText?.inputValue === "" ? "Type to Search" : "No options found"
        }
        closeMenuOnSelect={closeMenuOnSelect}
      />
      {validation.touched[name] && validation.errors[name]?.value ? (
        <FormFeedback className="d-flex" type="invalid">
          {validation.errors[name].value}
        </FormFeedback>
      ) : null}
    </div>
  );
};

export const InputCommon = ({
  name = "",
  label = "",
  validation,
  type = "text",
  isRequired = false,
  placeholder = "",
  ...props
}) => {
  return (
    <div className="mb-3 ">
      <Label className="form-label">
        {label}
        {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        // onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        onChange={(e) => {
          validation.setFieldValue(
            name,
            e.target.value === " " ? e.target.value.trim() : e.target.value
          );
        }}
        invalid={
          validation.touched[name] && validation.errors[name] ? true : false
        }
        {...props}
      />
      {validation.touched[name] && validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  );
};

export const SelectCommon = ({
  name,
  label,
  validation,
  uri,
  options,
  isMulti = false,
  isLoading = false,
  isClearable = true,
  isDisabled = false,
  dependency,
  isRequired = false,
  loadDependentData = () => {},
  dependencyValue = "",
  dependent = "",
}) => {
  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequired ? <span className="text-danger fw-bolder"> *</span> : null}
      </Label>

      <Select
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={colourOptions[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={true}
        name={name}
        options={options}
        isMulti={isMulti}
        placeholder={"Select " + label}
        onBlur={() => validation.setFieldTouched(name, true)}
        value={validation.values[name]}
        onChange={(option) => {
          validation.setFieldValue(name, option);
          loadDependentData(option.value);

          console.log("validataionnnnnnnn", option);
          validation.setFieldValue(dependency, { value: "", label: "" });
        }}
        noOptionsMessage={(inputText) =>
          dependency && dependencyValue === ""
            ? "Please select " + dependency + " First"
            : "No Record found for selected " + dependency
        }
      />
      {isMulti && validation.touched[name] && validation.errors[name] ? (
        <FormFeedback className="d-flex" type="invalid">
          {validation.errors[name]}
        </FormFeedback>
      ) : null}
      {!isMulti &&
      validation.touched[name] &&
      validation.errors[name]?.value ? (
        <FormFeedback className="d-flex" type="invalid">
          {validation.errors[name].value}
        </FormFeedback>
      ) : null}
    </div>
  );
};
