import { useFormik, Field, Formik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Input, Row } from "reactstrap";
import { SelectCommon } from "../../../../Common/FormCommonFormik";
// import { getDepartments } from "../../../store/actions";
import { ApiCall } from "../../../../Common/CommonCallApi";

import * as Yup from "yup";
function DesignationBulk({ setformValue, SaveButton }) {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getDepartments("all"));
  // }, []);

  const initialValue = {
    department: "",
    is_active: { label: "Active", value: "ACTIVE" },
  };
  const [form, setForm] = useState(initialValue);
  const [departments, setDepartments] = useState([]);
  const handleChangeForm = (e) => {
    setForm((prev) => ({
      ...prev,
      is_active: e.target.value,
    }));
  };

  useEffect(() => {
    ApiCall("get", "department", { is_active: "ACTIVE" }, setDepartments);
  }, []);

  // const { departments } = useSelector((state) => state.departments);

  const [designation, setDesignation] = useState();

  const handleRadioButtons = (e) => {
    validation.setFieldValue("isActive", e.target.value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      department:
        (designation && {
          value: designation?.department?.id || "",
          label: designation?.department?.name || "",
        }) ||
        "",
      isActive: (designation && designation.isActive) || "",
    },

    onSubmit: (values) => {
      const newDesignation = {
        is_active: values["isActive"],
        department: values["department"]?.value,
      };
      // save new user
      console.log(
        newDesignation,
        "newDesignation newDesignation newDesignation"
      );
      SaveButton(newDesignation);
    },
  });

  //  const handleSave =()=>{
  //   SaveButton()
  //  }
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row style={{ minHeight: "61vh" }}>
          <Col xs={12}>
            <SelectCommon
              name="department"
              label="Department"
              isRequired
              validation={validation}
              uri="department"
              options={departments.map((dep) => ({
                value: dep.id,
                label: dep.name,
              }))}
            />

            <div>
              <h6>
                Status<span style={{ color: "#dc3545;" }}>*</span>
              </h6>
              {/* <div className="d-flex">
              <input
                type="radio"
                id="age1"
                defaultChecked
                name="is_active"
                onChange={(e) => handleRadioButtons(e)}
                value="ACTIVE"
                className="me-1"
              />
              <label htmlFor="age1">Default</label>
            </div> */}
              <div className="d-flex">
                <input
                  type="radio"
                  id="age1"
                  name="is_active"
                  onChange={(e) => handleRadioButtons(e)}
                  value="ACTIVE"
                  className="me-1"
                />
                <label htmlFor="age1">Active</label>
              </div>

              <div className="d-flex">
                <input
                  type="radio"
                  id="age2"
                  name="is_active"
                  value="INACTIVE"
                  onChange={(e) => handleRadioButtons(e)}
                  className="me-1"
                />
                <label htmlFor="age2">InActive</label>
              </div>
            </div>
          </Col>
        </Row>

        <div className="text-end">
          <button
            type="submit"
            className="btn btn-primary save-user btn-success"
            style={{ float: "right", marginTop: "1rem", width: "95px" }}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

export default DesignationBulk;
