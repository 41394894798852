import { ApiCall } from "./CommonCallApi";

export const handleUpdateCommon = async (
  url,
  updatedField,
  offset,
  limit,
  setloading,
  setfunc,
  setCount,
  setModal,
  modal,
  setCheckedList,
  initialValue,
  setLoadingModal,
  setForm,
  ComponentAPI,
  urlChange
) => {
  if (typeof setLoadingModal === "function") {
    setLoadingModal(true);
  }
  await ApiCall("update", url, updatedField, null, setLoadingModal)
    .then((res) => {
      console.log(res);
      if (res != undefined) {
        if (typeof setLoadingModal === "function") {
          setLoadingModal(false);
        }
        setCheckedList([]);
        ApiCall(
          "get",
          urlChange ? ComponentAPI : url,
          { offset, limit },
          setfunc,
          setloading,
          setCount
        ).then((res) => {
          if (setForm) {
            if (typeof setForm === "function") {
              setForm([initialValue]);
            }
          }
          if (typeof setModal === "function") {
            setModal(!modal);
          }
        });
      }
    })
    .finally(() => {
      if (typeof setLoadingModal === "function") {
        setLoadingModal(false);
      }
    });
};
