import React, { useState, useEffect, Suspense } from "react";
import NavbarHeader from "./NavbarHeader";
import { useNavigate } from "react-router-dom";

import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import useIdleTimer from "../../Common/customHooks/useIdleTimerHook";
import Loader from "../../Common/Loader";
import JiraIssueCollector from "../../Common/IssueCollector";

function Layout() {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = window.localStorage.getItem("token");
    if (!auth) {
      navigate("/login");
    }
  }, []);
 
  const timeToIdle = 28800000; // 8 minutes in milliseconds
  const isIdle = useIdleTimer(timeToIdle);

  useEffect(() => {
    if (isIdle) {
      localStorage.clear();
      navigate("/login");
    }
  }, [isIdle]);
  const [sidebarToogle, setSidebarToogle] = useState(false);
  const handleSidebarToogle = () => {
    setSidebarToogle(!sidebarToogle);
  };


  // let [showInfo1, setShowInfo1] = useState(false);

  // const onClickOutside=()=>{
  // setShowInfo1(false)}
  return (
    <div className={sidebarToogle ? "sm" : "full"}>
      <NavbarHeader
        sidebarToogle={sidebarToogle}
        handleSidebarToogle={handleSidebarToogle}
        // show={showInfo1} onClickOutside= {onClickOutside}
      />
      <div className="LayoutDiv">
        <Sidebar />
       
        <div className="col main-Content">
          {/* kajsdlkjaskl */}
          <Suspense fallback={<Loader />}>
          {/* <JiraIssueCollector /> */}

            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default Layout;
