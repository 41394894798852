import React, { Fragment, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import NoRecordFound from "../Assets/images/norecordfound.gif";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Card,
} from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { isEqual } from "lodash";
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={4}>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
}
const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  className,
  toggleEdit,
  isColumnEdit,
  count,
  offset,
  onCustomNextPage,
  onHandleBackCustomPage,
  customPageSize,
  handleSortBy,
  setSelectedRowData = () => {},
  setShowDetails = () => {},
  handleMouseOver = () => {},
  handleMouseOut = () => {},
  // sortBy,
  // setSortBy,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    getToggleHideAllColumnsProps,
    allColumns,
    setGlobalFilter,
    rows,
    toggleHideColumn,
    state: { pageIndex, pageSize, hiddenColumns },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: customPageSize },

      // onPaginationChange: setPagination,
      // pageCount: parseInt(count / 10 + 1),
      // manualPagination: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const [sortBy, setSortBy] = useState({ order: "asc", order_by: "id" });
  
  const generateSortingIndicator = useCallback(
    (column) => {
      if (column.id === "checkbox" || column.sort === false) {
        return <UncontrolledDropdown className="mb-2"></UncontrolledDropdown>;
      }

      return (
        <UncontrolledDropdown>
          <DropdownToggle nav caret>
            {(column.id.trim() === sortBy.order_by ||
              column.sort_text === sortBy.order_by) &&
            sortBy.order === "desc"
              ? null
              : null}
            {(column.id.trim() === sortBy.order_by ||
              column.sort_text === sortBy.order_by) &&
            sortBy.order === "asc"
              ? null
              : null}
            <span className="font-size-18 mb-2 fw-normal">
              <i className="mdi mdi-chevron-down" />
            </span>
          </DropdownToggle>
          <DropdownMenu className="p-0 m-0">
            <React.Fragment>
              <DropdownItem
                onClick={() => {
                  handleSortBy({
                    order: "asc",
                    order_by: column.sort_text
                      ? column.sort_text
                      : column.id.toLowerCase().trim() || "id",
                  });
                  setSortBy({
                    order: "asc",
                    order_by: column.sort_text
                      ? column.sort_text
                      : column.id.trim() || "id",
                  });
                }}
              >
                <i className="mdi mdi-arrow-down" /> Sort A to Z
                {(column.id.trim() === sortBy.order_by ||
                  column.sort_text === sortBy.order_by) &&
                sortBy.order === "asc" ? (
                  <i className="mdi mdi-arrow-down" />
                ) : null}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleSortBy({
                    order: "desc",
                    order_by: column.sort_text
                      ? column.sort_text
                      : column.id.toLowerCase().trim() || "id",
                  });
                  setSortBy({
                    order: "desc",
                    order_by: column.sort_text
                      ? column.sort_text
                      : column.id.trim() || "id",
                  });
                }}
              >
                <i className="mdi mdi-arrow-up" /> Sort Z to A
                {(column.id.trim() === sortBy.order_by ||
                  column.sort_text === sortBy.order_by) &&
                sortBy.order === "desc" ? (
                  <i
                    // style={{ cursor: "pointer" }}
                    className="mdi mdi-arrow-down "
                  />
                ) : null}{" "}
              </DropdownItem>
            </React.Fragment>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
    [sortBy.order, sortBy.order_by]
  );

  useEffect(() => {
    setPageSize(customPageSize);
  }, [customPageSize]);
  return (
    <Fragment>
      {isColumnEdit && (
        <Modal isOpen={isColumnEdit} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit} tag="h4">
            Edit Column
          </ModalHeader>
          <ModalBody>
            <div>
              {allColumns.map((column, index) => {
                return index < 1 ? null : (
                  <div key={column.id}>
                    <label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        readOnly
                        checked={
                          hiddenColumns.includes(column.id) === true
                            ? false
                            : true
                        }
                        onClick={() => column.toggleHidden()}
                      />{" "}
                      <span>{column.Header}</span>
                    </label>
                  </div>
                );
              })}
              <br />
            </div>
          </ModalBody>
        </Modal>
      )}

      {/* </Row> */}
      {data.length === 0 ? (
        <div className="table-empty">
          <img alt="No record found" src={NoRecordFound} />
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table
              responsive
              bordered
              hover
              {...getTableProps()}
              // className="table-striped"
              // style={{ position: "relative" }}
            >
              <thead className="table table-nowrap m-0 p-0">
                {headerGroups.map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps({
                      style: {
                        paddingRight: 0,
                        marginRight: 0,
                        width: 40,
                      },
                    })}
                    className="m-0 p-0"
                  >
                    {headerGroup.headers.map((column) => (
                      <th key={column.id} className="mb-0 pb-0 me-1 pe-3  ">
                        <div className="mb-0 p-0 d-flex d-flex-inline">
                          <p className="mt-1 "> {column.render("Header")}</p>
                          {generateSortingIndicator(column)}
                        </div>

                        {/* <Filter column={column} /> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr
                        onClick={() => {
                          console.log("row", row?.original);
                          setSelectedRowData(row?.original);
                          setShowDetails();
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className="m-0 pb-2"
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  // paddingRight: 0,
                                  // marginRight: "5px",
                                },
                              })}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center w-100 mt-1">
            <Col className="col-sm-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={onHandleBackCustomPage}
                  disabled={offset + customPageSize < customPageSize + 1}
                >
                  <span className="mdi mdi-arrow-left "></span>
                </Button>
              </div>
            </Col>
            <Col className="col-sm-auto d-none d-md-block">
              Page{" "}
              <strong>
                {parseInt(offset / customPageSize + 1)} of{" "}
                {parseInt(
                  count % customPageSize === 0
                    ? count / customPageSize
                    : count / customPageSize + 1
                )}
              </strong>
            </Col>

            <Col className="col-sm-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => {
                    onCustomNextPage();
                  }}
                  disabled={count < offset + customPageSize + 1}
                >
                  <span className="mdi mdi-arrow-right"></span>
                </Button>
              </div>
            </Col>
          </Row>
          {/* </Card> */}
        </>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableContainer);
// export default React.memo(TableContainer, (prevProps, nextProps) => {
//   return isEqual(prevProps.data, nextProps.data);
// });
