import { configureStore } from '@reduxjs/toolkit'
import featureSlice from './Slices/featureSlice'
import userInformationSlice from './Slices/userInformationSlice'


export const store = configureStore({
  reducer: {
    featureSlice: featureSlice,
    userInformationSlice:userInformationSlice

  },
})