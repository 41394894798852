
import React, {  useEffect, useState } from "react";

import {
  Row,
  Col,

  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
//redux
// import { useSelector, useDispatch } from "react-redux";

import {  Link, Outlet, useLocation, useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
// import { loginUser } from "../../store/actions";

// import images
import logo from "../../Assets/images/logo.png";

import CarouselPage from "./CarouselPage";
import { postLogin } from "../../components/auth";


const PublicLayout = (props) => {

  document.title = "Login | Lucky Cement";


const navigate=useNavigate()

  useEffect(() => {
    const auth=window.localStorage.getItem('token')
    if (auth) {
       navigate('/home')
     }
  }, []);
  return (
    <React.Fragment>
    <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
           <Outlet />
         <CarouselPage />
          </Row>
        </Container>
      </div>
      
    </React.Fragment>
  );
};

export default PublicLayout;

