import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import featureSlice, {
  setFeaturesArray,
  clearFeaturesArray,
} from "../../store/Slices/featureSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
// import { loginUser } from "../../store/actions";

// import images
import logo from "../../Assets/images/logo.png";

import CarouselPage from "./CarouselPage";
import { postLogin } from "../../components/auth";
import { toast } from "react-toastify";
import { ApiCall } from "../../Common/CommonCallApi";
import { getApiCall } from "../../Common/ApiCall";
import userInformationSlice, {
  setUserInformationSliceArray,
} from "../../store/Slices/userInformationSlice";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState([]);
  document.title = "Login | Lucky Cement";
  const [preLoader, setPreloader] = useState(false);
  const handleSubmit = async (value) => {
    setPreloader(true);
    const response = await postLogin({
      email: value.email.toLowerCase(),
      password: value.password,
    });
    setPreloader(false);
    console.log(response?.data?.token);
    if (response?.data?.token) {
      window.localStorage.setItem("authUser", response?.data?.token);
      window.localStorage.setItem("token", response?.data?.token);
      dispatch(setFeaturesArray(response?.data?.role));
      getApiCall({ self: true }, "user").then((res) => {
        dispatch(setUserInformationSliceArray(res?.data[0]));
        navigate("/home");
        toast.success("LoggedIn Successfully");
      });
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required("Please Enter Your Email")
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "valid Email required"
        ),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      //login user action
      // dispatch(loginUser(values, props.history, from));
      handleSubmit(values);
    },
  });

  return (
    <React.Fragment>
      <Col lg={4} md={5} className="col-xxl-3">
        <div className="auth-full-page-content d-flex p-sm-5 p-4">
          <div className="w-100">
            <div className="d-flex flex-column h-100">
              <div className="mb-4 mb-md-5 text-center">
                <Link to="/" className="d-block auth-logo">
                  <img src={logo} alt="" height="80" />
                </Link>
              </div>
              <div className="auth-content my-auto">
                <div className="text-center">
                  <h5 className="mb-0">Sign In To Continue</h5>
                </div>
                <Form
                  className="custom-form mt-4 pt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter Email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type="password"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="row mb-4">
                    <div className="col">
                      {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div> */}

                      <div className="mt-3 d-grid">
                        {preLoader ? (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            <span
                              className="spinner-border spinner-border-sm "
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="m-2"> Logging In</span>
                          </button>
                        ) : (
                          <button
                            disabled={preLoader}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="mt-5 text-center">
                  <p className="text-muted mb-0">
                    <Link
                      to="/forgot-password"
                      className="text-primary fw-semibold "
                    >
                      Forgot Password?
                    </Link>
                  </p>
                </div>
              </div>
              <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} Lucky Cement.
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    fontFamily: "system-ui",
                  }}
                >
                  Developed by Joyn Digital
                </p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Login;
