import { ToastContainer, toast } from 'react-toastify';
export const handleValidtionSubmit=(errors)=>{

    const [name,value]=    Object?.entries(errors)[0]
    const hasErrorMessage =  value?.includes("must be a `object` type, but the final value was:");
    const hasErrorMessageAllFields =  value?.includes('but the final value was: `[]`.')
    if(hasErrorMessageAllFields){
toast('Please Fill all required fields with *')
return
    }
    if(hasErrorMessage){
        const [name,value]=    Object?.entries(errors)[0]
   
        const firstWord = value?.split(" ")[0];
const finalMessage  = `${firstWord} is required`;
toast(finalMessage)
    }else{
        const [name,value]=    Object?.entries(errors)[0]
        toast(value)
    }
   
}