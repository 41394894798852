import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { ApiCall } from "../../../../Common/CommonCallApi";

import { useFormik } from "formik";
// import { useSelector, useDispatch } from "react-redux";
// import { getRegions } from "../../../store/actions";
import { SelectCommon } from "../../../../Common/FormsCommon";
import { loadOneTimeOptions } from "../../../../Common/optionRequest";
import { clearFormFields } from "../../../../Common/ClearDependencyArray";

function WarehouseBulk({ SaveButton }) {
  const initialValue = {
    region: "",
    city: "",
    zone: "",
    category: "",
    is_active: "",
  };
  const [form, setForm] = useState(initialValue);
  const [warehouses, setWarehouse] = useState();
  const [isCityLoading, setCityIsLoading] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [isZoneLoading, setZoneIsLoading] = useState(false);
  const [zonesOptions, setZonesOptions] = useState([]);

  // const dispatch = useDispatch();
  // const regions = useSelector((state) => state.regionsReducer?.regions);
  const [regions, setRegions] = useState([]);
  const handleInputChange = (event, names, sel, dependencyArray) => {
    if (dependencyArray && dependencyArray.length != 0) {
      clearFormFields(dependencyArray, { form }, setForm);
      switch (names) {
        case "city":
          setZonesOptions([]);
          loadOneTimeOptions(
            `area/zone?city=${form.region?.value}`,
            setCityIsLoading,
            setCitiesOptions
          );
          break;
        default:
          break;
      }
    }
    debugger;
    if (names) {
      if (sel == undefined) {
        setForm((prev) => ({
          ...prev,
          [names]: "",
        }));
        return;
      } else {
        setForm((prev) => ({
          ...prev,
          [names]: sel,
        }));
        return;
      }
    }
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    // dispatch(getRegions('all'));
    ApiCall("get", "area/region", { is_active: "ACTIVE" }, setRegions);
  }, []);
  const handleSave = () => {
    const updateWarehouse = {
      category: form.category?.value,
      region: form.region.value,
      city: form.city.value,
      zone: form.zone.value,
      is_active: form.is_active,
    };
    SaveButton(updateWarehouse);
  };

  const validationSchema = Yup.object({
    region: Yup.object().shape({
      value: Yup.string().required("Please Select Region"),
    }),

    city: Yup.object().shape({
      value: Yup.string().required("Please Select City"),
    }),
    zone: Yup.object().shape({
      value: Yup.string().required("Please Select Zone"),
    }),
  });
  return (
    <div className="">
      <Row>
        <SelectCommon
          validationSchema={validationSchema.fields.region}
          isRequired="true"
          name="region"
          label={"Region Name"}
          dependencyArray={["city", "zone"]}
          value={form.region}
          onChange={handleInputChange}
          dependency={"country"}
          dependencyValue={form.country}
          options={regions.map((region) => ({
            value: region.id,
            label: region.name,
          }))}
          loadDependentData={(e) =>
            loadOneTimeOptions(
              `area/city?region=${e}`,
              setCityIsLoading,
              setCitiesOptions
            )
          }
        />

        <SelectCommon
          validation={validationSchema.fields.city}
          isRequired="true"
          name="city"
          label={"City Name"}
          value={form.city}
          onChange={handleInputChange}
          dependencyArray={["zone"]}
          isLoading={isCityLoading}
          dependency={"region"}
          dependencyValue={form.region}
          options={citiesOptions.map((region) => ({
            value: region.value,
            label: region.label,
          }))}
          loadDependentData={(e) =>
            loadOneTimeOptions(
              `area/zone?city=${e}`,
              setZoneIsLoading,
              setZonesOptions
            )
          }
        />

        <SelectCommon
          validation={validationSchema.fields.zone}
          isRequired="true"
          name="zone"
          label={"Zone Name"}
          onChange={handleInputChange}
          isLoading={isZoneLoading}
          value={form.zone}
          dependency={"city"}
          dependencyValue={form.city}
          options={zonesOptions.map((region) => ({
            value: region.value,
            label: region.label,
          }))}
        />

        <SelectCommon
          onChange={handleInputChange}
          name="category"
          value={form.category}
          label="Category"
          // uri="department"
          options={[
            {
              value: "IL",
              label: "Internal",
            },
            {
              value: "EL",
              label: "External",
            },
          ]}
        />

        <div>
          <h6>
            Status<span style={{ color: "#dc3545;" }}>*</span>{" "}
          </h6>

          {/* <div className="d-flex">
            <input
              type="radio"
              id="age1"
              style={{ marginBottom: "0.4rem" }}
              defaultChecked
              name="is_active"
              onChange={() => setForm((prev) => ({ ...prev, is_active: "" }))}
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Default</label>
          </div> */}
          <div className="d-flex">
            <input
              type="radio"
              id="age1"
              style={{ marginBottom: "0.4rem" }}
              name="is_active"
              onChange={() =>
                setForm((prev) => ({ ...prev, is_active: "ACTIVE" }))
              }
              value="ACTIVE"
              className="me-1"
            />
            <label htmlFor="age1">Active</label>
          </div>

          <div className="d-flex">
            <input
              type="radio"
              style={{ marginBottom: "0.4rem" }}
              id="age2"
              name="is_active"
              value="INACTIVE"
              onChange={() =>
                setForm((prev) => ({ ...prev, is_active: "INACTIVE" }))
              }
              className="me-1"
            />
            <label htmlFor="age2">InActive</label>
          </div>
        </div>
      </Row>
      <button
        type="submit"
        onClick={() => handleSave()}
        style={{
          float: "right",
          marginTop: "1rem",
          width: "95px",
          marginBottom: "40px",
        }}
        className="btn btn-primary save-user mb-5"
      >
        Save
      </button>
    </div>
  );
}

export default WarehouseBulk;
