import React from "react";
import { useState, useCallback } from "react";
import { Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { loadOptions } from "./optionRequest";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useDebounce, { useDebouncedFunction } from "./customHooks/useDebounce";

export const InputCommon = ({
  isRequired = false,
  disabled = false,
  name,
  label,
  value,
  onChange,
  type = "text",
  placeholder = "",
  min,
  max,
  validationSchema = [],
  Suberrors = "",
}) => {
  const [error, setError] = useState(Suberrors);
  const [touched, setTouched] = useState(false);
  const handelBlur = (event) => {
    const { name, value } = event.target;

    if (!Array.isArray(validationSchema)) {
      validationSchema
        .validate(value, validationSchema)
        .then(() => {
          setError("");
        })
        .catch((validationError) => {
          setError(validationError.message);
        });

      onChange(event);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setTouched(true);

    if (!Array.isArray(validationSchema)) {
      validationSchema
        .validate(value)
        .then(() => {
          setError("");
        })
        .catch((validationError) => {
          setError(validationError.message);
        });
    }
    onChange(event);
  };
  const checkPlaceholder = (val) => {
    if (val == "" && disabled) {
      return label;
    } else if (val != "" && disabled) {
      return placeholder;
    } else {
      return `Enter ${label}`;
    }
  };
  return (
    <div className="mb-3 ">
      <div>
        <Label htmlFor={name} className="customize-label">
          {label}
          {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
        </Label>
        <Input
          id={name}
          name={name}
          max={max}
          type={type}
          disabled={disabled}
          min={min}
          value={value}
          placeholder={checkPlaceholder(placeholder)}
          onChange={handleChange}
          onBlur={handelBlur}
        />
        <FormFeedback className="d-flex" type="invalid">
          {error}
        </FormFeedback>{" "}
      </div>{" "}
    </div>
  );
};

export const NewAsyncSelectMultiCommon = ({
  name,
  label,
  validationSchema = [],
  uri,
  value = null,
  defaultOptions,
  isMulti = false,
  Suberrors = "",
  isRequired = false,
  closeMenuOnSelect = true,
  handleChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const [validationError, setValidationError] = useState(Suberrors);

  if (Array.isArray(value) && name == "users") {
    const a = value.map((index, key) => {
      return {
        value: index?.guid || "",
        label: index?.first_name || "",
      };
    });
  }

  const validateField = async (value) => {
    if (validationSchema.length != 0) {
      try {
        await validationSchema.validate(value);
        setValidationError("");
        return true;
      } catch (error) {
        setValidationError(error.message);
        return false;
      }
    }
  };

  const handleSelectChange = async (selected) => {
    setSelectedOption(selected);
    const fieldValue = selected ? selected : "";
    const isFieldValid = await validateField(fieldValue);
    if (
      Array.isArray(selected) &&
      (name == "regional_manager" ||
        name == "zonal_manager" ||
        name == "dsr" ||
        name == "distributor")
    ) {
      handleChange(null, name, selected);
      return;
    }
    if (Array.isArray(selected)) {
      // const val = selected.map((index)=>{
      //   {index.value,
      //     index.name}
      // }
      //    );
      const val = selected.map((index) => ({
        value: index.value,
        label: index.label,
      }));
      handleChange(null, name, val);
      return;
    }
    handleChange(null, name, selected);
  };

  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
      </Label>
      <AsyncSelect
        loadOptions={(searchTerm) => loadOptions(searchTerm, uri)}
        id={name}
        name={name}
        isMulti={isMulti}
        isClearable
        placeholder={"Type to Search "}
        value={selectedOption}
        onChange={handleSelectChange}
        formatCreateLabel={(inputText) => `${inputText}`}
        defaultOptions={defaultOptions ? defaultOptions : []}
        noOptionsMessage={(inputText) =>
          inputText?.inputValue === "" ? "Type to Search" : "No options found"
        }
        closeMenuOnSelect={closeMenuOnSelect}
      />
      {validationError != "" ? (
        <FormFeedback className="d-flex" type="invalid">
          {validationError}
        </FormFeedback>
      ) : null}
    </div>
  );
};
export const NewAsyncSelectCommon = ({
  name,
  label,
  validationSchema = [],
  uri,
  value = null,
  defaultOptions,
  isMulti = false,
  Suberrors = "",
  isRequired = false,
  closeMenuOnSelect = true,
  handleChange,
  isDisabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");

  const [validationError, setValidationError] = useState(Suberrors);

  if (Array.isArray(value) && name == "users") {
    const a = value.map((index, key) => {
      return {
        value: index?.guid || "",
        label: index?.first_name || "",
      };
    });
  }

  const validateField = async (value) => {
    if (validationSchema.length != 0) {
      try {
        await validationSchema.validate(value);
        setValidationError("");
        return true;
      } catch (error) {
        setValidationError(error.message);
        return false;
      }
    }
  };

  const handleSelectChange = async (selected) => {
    setSelectedOption(selected);
    const fieldValue = selected ? selected : "";
    const isFieldValid = await validateField(fieldValue);
    if (
      Array.isArray(selected) &&
      (name == "regional_manager" ||
        name == "zonal_manager" ||
        name == "dsr" ||
        name == "distributor")
    ) {
      handleChange(null, name, selected);
      return;
    }
    if (Array.isArray(selected)) {
      const val = selected.map((index) => index.value);
      handleChange(null, name, val);
      return;
    }
    handleChange(null, name, selected);
  };

  // const debouncedSearchTerm = useDebounce(searchTerm, 3000);

  const debouncedLoadOptions = useDebouncedFunction(
    async (searchTerm, callback) => {
      const options = await loadOptions(searchTerm, uri);
      callback(options);
    },
    2000
  );

  return (
    <div className="mb-3">
      <Label className="form-label" style={{ fontWeight: "bold" }}>
        {label}

        {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
      </Label>
      <AsyncSelect
        isDisabled={isDisabled}
        // loadOptions={(searchTerm) => loadOptions(searchTerm, uri)}
        loadOptions={debouncedLoadOptions}
        onInputChange={setSearchTerm}
        id={name}
        name={name}
        isMulti={isMulti}
        isClearable
        placeholder={"Type to Search "}
        value={selectedOption}
        onChange={handleSelectChange}
        formatCreateLabel={(inputText) => `${inputText}`}
        defaultOptions={defaultOptions ? defaultOptions : []}
        noOptionsMessage={(inputText) =>
          inputText?.inputValue === "" ? "Type to Search" : "No options found"
        }
        closeMenuOnSelect={closeMenuOnSelect}
      />
      {validationError != "" ? (
        <FormFeedback className="d-flex" type="invalid">
          {validationError}
        </FormFeedback>
      ) : null}
    </div>
  );
};

export const AsyncSelectCommon = ({
  name,
  label,
  validationSchema = [],
  uri,
  value = null,
  defaultOptions,
  isMulti = false,
  Suberrors = "",
  isRequired = false,
  closeMenuOnSelect = true,
  handleChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  // const [searchTerm, setSearchTerm] = useState('');

  const [validationError, setValidationError] = useState(Suberrors);

  if (Array.isArray(value) && name == "users") {
    const a = value.map((index, key) => {
      return {
        value: index?.guid || "",
        label: index?.first_name || "",
      };
    });
  }

  const validateField = async (value) => {
    if (validationSchema.length != 0) {
      try {
        await validationSchema.validate(value);
        setValidationError("");
        return true;
      } catch (error) {
        setValidationError(error.message);
        return false;
      }
    }
  };
  // const debouncedLoadOptions = useDebouncedFunction(async (searchTerm, callback) => {
  //   const options = await loadOptions(searchTerm, uri);
  //   callback(options);
  // }, 2000);

  const handleSelectChange = async (selected) => {
    setSelectedOption(selected);
    const fieldValue = selected ? selected : "";
    const isFieldValid = await validateField(fieldValue);
    if (
      Array.isArray(selected) &&
      (name == "regional_manager" ||
        name == "zonal_manager" ||
        name == "dsr" ||
        name == "distributor")
    ) {
      handleChange(null, name, selected);
      return;
    }
    if (Array.isArray(selected)) {
      const val = selected.map((index) => index.value);
      handleChange(null, name, val);
      return;
    }
    handleChange(null, name, selected?.value);
  };

  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
      </Label>
      <AsyncSelect
        //  loadOptions={debouncedLoadOptions}
        loadOptions={(searchTerm) => loadOptions(searchTerm, uri)}
        id={name}
        name={name}
        isMulti={isMulti}
        // onInputChange={setSearchTerm}

        isClearable
        placeholder={"Type to Search "}
        value={selectedOption}
        onChange={handleSelectChange}
        formatCreateLabel={(inputText) => `${inputText}`}
        defaultOptions={defaultOptions ? defaultOptions : []}
        noOptionsMessage={(inputText) =>
          inputText?.inputValue === "" ? "Type to Search" : "No options found"
        }
        closeMenuOnSelect={closeMenuOnSelect}
      />
      {validationError != "" ? (
        <FormFeedback className="d-flex" type="invalid">
          {validationError}
        </FormFeedback>
      ) : null}
    </div>
  );
};

const SelectCommonMemo = ({
  name,
  label,
  validationSchema = [],
  uri,
  options,
  isMulti = false,
  isLoading = false,
  isClearable = true,
  Suberrors = "",
  isDisabled = false,
  dependencyArray = [],
  dependency,
  isRequired = false,
  placeholder = "",
  loadDependentData = () => {},
  loadDependentDataWithParent = () => {},
  dependencyValue = "",
  value = "",
  onChange,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(Suberrors);

  const handleChange = (event) => {
    setTouched(true);
    if (!Array.isArray(validationSchema)) {
      validationSchema
        .validate(event)
        .then(() => {
          setError("");
        })
        .catch((validationError) => {
          setError(validationError.message);
        });
    }
    if (Array.isArray(event) && isMulti) {
      const val = event.map((index) => index.value);
      onChange(null, name, event, dependencyArray);
      return;
    }
    if (Array.isArray(event)) {
      const val = event.map((index) => index.value);
      onChange(null, name, val, dependencyArray);
      return;
    }
    onChange(null, name, event, dependencyArray);
    if (event == null || event?.value == undefined) {
      loadDependentData(0);
    } else {
      loadDependentData(event?.value);
    }

    loadDependentDataWithParent(event);
  };

  const handleBlur = () => {
    setTouched(true);
    if (!Array.isArray(validationSchema)) {
      try {
        validationSchema
          .validateAt(value)
          .then(() => {
            setError("");
          })
          .catch((err) => {
            setError(err.message);
          });
      } catch (err) {
        toast.error(err);
      }
    }
  };

  return (
    <div className="mb-3" key={name}>
      <Label className="form-label customize-label">
        {label}
        {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
      </Label>

      <Select
        defaultValue={value}
        className="basic-single"
        classNamePrefix="select"
        menuPosition="fixed"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={true}
        name={name}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder == "" ? `Select ${label}` : placeholder}
        onBlur={handleBlur}
        value={value}
        onChange={(e) => handleChange(e)}
        noOptionsMessage={() =>
          dependency && dependencyValue === ""
            ? `Please Select ${dependency} first`
            : `No Record Found For Selected ${dependency}`
        }
        {...props}
      />

      {error != "" ? (
        <FormFeedback className="d-flex" type="invalid">
          {error}
        </FormFeedback>
      ) : null}
    </div>
  );
};

export const SelectCommon = React.memo(SelectCommonMemo);

const NewSelectCommonMemo = ({
  name,
  label,
  validationSchema = [],
  uri,
  index,
  options,
  isMulti = false,
  isLoading = false,
  isClearable = true,
  Suberrors = "",
  isDisabled = false,
  dependencyArray = [],
  dependency,
  isRequired = false,
  placeholder = "",
  loadDependentData = () => {},
  loadDependentDataWithParent = () => {},
  dependencyValue = "",
  value = "",
  onChange,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(Suberrors);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call delay (remove this in your actual implementation)
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(delay);
  }, []);

  const handleChange = (event) => {
    setTouched(true);
    if (event === null || event === undefined) {
      onChange(null, name, event, dependencyArray, index);
      return;
    }
    if (!Array.isArray(validationSchema)) {
      validationSchema
        .validate(event)
        .then(() => {
          setError("");
        })
        .catch((validationError) => {
          setError(validationError.message);
        });
    }
    if (Array.isArray(event) && isMulti) {
      const val = event.map((item) => item.value);
      onChange(null, name, event, dependencyArray, index);
      return;
    }
    if (Array.isArray(event)) {
      const val = event.map((item) => item.value);
      onChange(null, name, val, dependencyArray, index);
      return;
    }
    onChange(null, name, event, dependencyArray, index);
    loadDependentData(event?.value);
    loadDependentDataWithParent(event);
  };

  const handleBlur = () => {
    setTouched(true);
    if (!Array.isArray(validationSchema)) {
      try {
        validationSchema
          .validateAt(value)
          .then(() => {
            setError("");
          })
          .catch((err) => {
            setError(err.message);
          });
      } catch (err) {
        toast.error(err);
      }
    }
  };

  return (
    <div className="mb-3" key={name}>
      <Label className="form-label customize-label">
        {label}
        {isRequired ? <span className="text-danger fw-bolder">*</span> : null}
      </Label>

      <Select
        defaultValue={value}
        className="basic-single"
        classNamePrefix="select"
        menuPosition="fixed"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={true}
        name={name}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder == "" ? `Select ${label}` : placeholder}
        onBlur={handleBlur}
        value={value}
        onChange={(e) => handleChange(e)}
        noOptionsMessage={() =>
          dependency && dependencyValue === ""
            ? `Please Select ${dependency} first`
            : `No Record Found For Selected ${dependency}`
        }
        {...props}
      />

      {error != "" ? (
        <FormFeedback className="d-flex" type="invalid">
          {error}
        </FormFeedback>
      ) : null}
    </div>
  );
};

export const NewSelectCommon = React.memo(NewSelectCommonMemo);
